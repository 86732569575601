/** Calculates the first and last dates in a given time-period and returns them in the format M-d-YYYY */
export const getStartEndDates = (timePeriod) => {
	var startDate, endDate
	const today = new Date()
	if (timePeriod === 'Week') {
		const firstOfTheWeek = today.getDate() - today.getDay() //Get the first day of the week - Sunday
		const sunday = new Date(today.setDate(firstOfTheWeek))
		const start = new Date(sunday.valueOf())
		const saturday = new Date(start.setDate(start.getDate() + 6))
		startDate = `${sunday.getMonth() + 1}-${sunday.getDate()}-${sunday.getFullYear()}`
		endDate = `${saturday.getMonth() + 1}-${saturday.getDate()}-${saturday.getFullYear()}`
	} else if (timePeriod === 'Month') {
		const firstOfTheMonth = new Date(today.setDate(1))
		const start = new Date(firstOfTheMonth.valueOf())
		start.setMonth(start.getMonth() + 1)
		const endOfTheMonth = new Date(start.setDate(start.getDate() - 1))
		startDate = `${firstOfTheMonth.getMonth() + 1}-${firstOfTheMonth.getDate()}-${firstOfTheMonth.getFullYear()}`
		endDate = `${endOfTheMonth.getMonth() + 1}-${endOfTheMonth.getDate()}-${endOfTheMonth.getFullYear()}`
	} else if (timePeriod === 'Quarter') {
		const month = today.getMonth()
		var firstOfTheQuarter, endOfTheQuarter
		if (month < 3) {
			firstOfTheQuarter = new Date(today.getFullYear(), 0, 1, 0, 0, 0, 0)
			endOfTheQuarter = new Date(today.getFullYear(), 3, 1, 0, 0, 0, 0)
			endOfTheQuarter.setDate(endOfTheQuarter.getDate() - 1)
		} else if (month >= 3 && month < 6) {
			firstOfTheQuarter = new Date(today.getFullYear(), 3, 1, 0, 0, 0, 0)
			endOfTheQuarter = new Date(today.getFullYear(), 6, 1, 0, 0, 0, 0)
			endOfTheQuarter.setDate(endOfTheQuarter.getDate() - 1)
		} else if (month >= 6 && month < 9) {
			firstOfTheQuarter = new Date(today.getFullYear(), 6, 1, 0, 0, 0, 0)
			endOfTheQuarter = new Date(today.getFullYear(), 9, 1, 0, 0, 0, 0)
			endOfTheQuarter.setDate(endOfTheQuarter.getDate() - 1)
		} else {
			firstOfTheQuarter = new Date(today.getFullYear(), 9, 1, 0, 0, 0, 0)
			endOfTheQuarter = new Date(today.getFullYear(), 0, 1, 0, 0, 0, 0)
			endOfTheQuarter.setDate(endOfTheQuarter.getDate() - 1)
		}
		startDate = `${firstOfTheQuarter.getMonth() + 1}-${firstOfTheQuarter.getDate()}-${firstOfTheQuarter.getFullYear()}`
		endDate = `${endOfTheQuarter.getMonth() + 1}-${endOfTheQuarter.getDate()}-${endOfTheQuarter.getFullYear()}`
	} else {
		const firstOfTheYear = new Date(today.getFullYear(), 0, 1, 0, 0, 0, 0)
		var endOfTheYear = new Date(today.getFullYear() + 1, 0, 1, 0, 0, 0, 0)
		endOfTheYear.setDate(endOfTheYear.getDate() - 1)
		startDate = `${firstOfTheYear.getMonth() + 1}-${firstOfTheYear.getDate()}-${firstOfTheYear.getFullYear()}`
		endDate = `${endOfTheYear.getMonth() + 1}-${endOfTheYear.getDate()}-${endOfTheYear.getFullYear()}`
	}

	return {
		startDate,
		endDate
	}
}
