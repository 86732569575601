import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { GlobalContext } from '../../../../helpers/GlobalContext'

const PREFIX = 'Controls'
const classes = {
	item: `${PREFIX}-item`
}

const GridStyled = styled(Grid)(() => ({
	[`& .${classes.item}`]: {
		paddingLeft: 3,
		paddingRight: 3
	}
}))

const Controls = () => {
	const { setYoutubeControlPanel, youtubeControlPanel } = useContext(GlobalContext)

	return (
		<GridStyled
			item
			container
			direction='row'
			alignItems='center'
			justifyContent='center' //{isDesktop ? 'space-between' : 'flex-start'}
			sx={{ margin: 0, paddingBottom: 2 }}
		>
			<Grid item className={classes.item}>
				<ToggleButtonGroup
					size='small'
					name='view'
					value={youtubeControlPanel.view}
					exclusive
					onChange={(e) => setYoutubeControlPanel((prevState) => ({ ...prevState, view: e.target.value }))}
					sx={{ paddingRight: '3px' }}
				>
					<ToggleButton name='view' value='channels'>
						channels
					</ToggleButton>
					<ToggleButton name='view' value='topics'>
						topics
					</ToggleButton>
				</ToggleButtonGroup>
			</Grid>

			<Grid item className={classes.item}>
				<ToggleButtonGroup
					size='small'
					name='comparison'
					disabled={youtubeControlPanel.view === 'topics'}
					value={youtubeControlPanel.view === 'channels' ? youtubeControlPanel.comparison : 'seconds'}
					exclusive
					onChange={(e) => setYoutubeControlPanel((prevState) => ({ ...prevState, comparison: e.target.value }))}
					sx={{ paddingLeft: '3px' }}
				>
					<ToggleButton name='comparison' value='seconds'>
						seconds
					</ToggleButton>
					<ToggleButton name='comparison' value='count'>
						count
					</ToggleButton>
				</ToggleButtonGroup>
			</Grid>
		</GridStyled>
	)
}

export default Controls
