import React, { createContext, useEffect, useState } from 'react'

export const TracersContext = createContext()

const TracersContextProvider = (props) => {
	//Initial state for Stats
	const [menuState, setMenuState] = useState('sleep') //sleep, youtube, clickup

	const [stats, setStats] = useState({
		previousQuarter: {
			wakeup: 0,
			quantity: 0,
			bedtime: 0,
			duration: 0
		},
		previousMonth: {
			bedtime: 0,
			duration: 0,
			wakeup: 0,
			quantity: 0
		},
		currentQuarter: {
			wakeup: 0,
			quantity: 0,
			bedtime: 0,
			duration: 0
		},
		previousYear: {
			bedtime: 0,
			quantity: 0,
			wakeup: 0,
			duration: 0
		},
		previousWeek: {
			wakeup: 0,
			quantity: 0,
			bedtime: 0,
			duration: 0
		},
		type: null,
		today: {
			wakeup: 0,
			bedtime: 0,
			duration: 0,
			date: ''
		},
		currentWeek: {
			duration: 0,
			bedtime: 0,
			wakeup: 0,
			quantity: 0
		},
		currentMonth: {
			quantity: 0,
			bedtime: 0,
			wakeup: 0,
			duration: 0
		},
		currentYear: {
			duration: 0,
			bedtime: 0,
			wakeup: 0,
			quantity: 0
		}
	})

	const [todayStats, setTodayStats] = useState({
		bedtime: '',
		wakeup: '',
		duration: '',
		date: '',
		score: 0
	})
	const [weekStats, setWeekStats] = useState({
		currentWeek: {
			duration: '',
			bedtime: '',
			wakeup: '',
			score: 0
		},
		previousWeek: {
			wakeup: '',
			bedtime: '',
			duration: '',
			improvedUpD: [],
			improvedUpB: [],
			improvedUpW: []
		}
	})
	const [monthStats, setMonthStats] = useState({
		currentMonth: {
			duration: '',
			bedtime: '',
			wakeup: '',
			score: 0
		},
		previousMonth: {
			wakeup: '',
			bedtime: '',
			duration: '',
			improvedUpD: [],
			improvedUpB: [],
			improvedUpW: []
		}
	})
	const [quarterStats, setQuarterStats] = useState({
		currentQuarter: {
			duration: '',
			bedtime: '',
			wakeup: '',
			score: 0
		},
		previousQuarter: {
			wakeup: '',
			bedtime: '',
			duration: '',
			improvedUpD: [],
			improvedUpB: [],
			improvedUpW: []
		}
	})
	const [yearStats, setYearStats] = useState({
		currentYear: {
			duration: '',
			bedtime: '',
			wakeup: '',
			score: 0
		},
		previousYear: {
			wakeup: '',
			bedtime: '',
			duration: '',
			improvedUpD: [],
			improvedUpB: [],
			improvedUpW: []
		}
	})
	const [timePeriod, setTimePeriod] = useState('')
	const [loading, setLoading] = useState(true)

	const calculateScoreAttribute = (bedtime, bedtimeGoal) => {
		const bedtimeDif = Math.abs(bedtimeGoal - bedtime)
		let bedtimeScore = 0.0
		if (bedtimeDif <= 10) {
			bedtimeScore = 1.0
		} else if (bedtimeDif <= 15) {
			bedtimeScore = 0.95 + ((15 - bedtimeDif) / 5) * 0.05 //95% + x% of the 5%pts between this and the previous score (ie. 100% - 95%)
		} else if (bedtimeDif <= 30) {
			bedtimeScore = 0.9 + ((30 - bedtimeDif) / 15) * 0.05 //{min % allowed} + ((({max min} - {actual min}) / {max min - prev. max min}) * {%pt dif between this and last min % allowed})
		} else if (bedtimeDif <= 60) {
			bedtimeScore = 0.8 + ((60 - bedtimeDif) / 30) * 0.1
		} else if (bedtimeDif <= 90) {
			bedtimeScore = 0.7 + ((90 - bedtimeDif) / 30) * 0.1
		} else if (bedtimeDif <= 120) {
			bedtimeScore = 0.6 + ((120 - bedtimeDif) / 30) * 0.1
		} else if (bedtimeDif <= 240) {
			bedtimeScore = 0.0 + ((240 - bedtimeDif) / 120) * 0.6
		}
		return bedtimeScore
	}

	const calculateStats = (stats, bedtime, wakeup, duration, quantity) => {
		const durationTotalMin = duration / quantity
		const bedtimeMin = bedtime / quantity
		const wakeupMin = wakeup / quantity
		const durationMin = Math.round(durationTotalMin % 60) === 60 ? 59 : Math.round(durationTotalMin % 60)

		var bedtimeDate = new Date('January 1, 2000 00:00:00')
		bedtimeDate.setMinutes(bedtimeDate.getMinutes() + bedtimeMin)
		var wakeupDate = new Date('January 1, 2000 00:00:00')
		wakeupDate.setMinutes(wakeupDate.getMinutes() + wakeupMin)

		const durationGoal =
			stats.bedtimeGoal < 0 ? Math.abs(stats.bedtimeGoal) + stats.wakeupGoal : stats.wakeupGoal - stats.bedtimeGoal
		const bedtimeScore = calculateScoreAttribute(bedtimeMin, stats.bedtimeGoal) * 0.25
		const wakeupScore = calculateScoreAttribute(wakeupMin, stats.wakeupGoal) * 0.25
		const durationScore = calculateScoreAttribute(durationTotalMin, durationGoal) * 0.5
		const score = Math.round((bedtimeScore + wakeupScore + durationScore) * 100)

		return {
			duration: `${Math.floor(durationTotalMin / 60)}hr ${durationMin}min`,
			bedtime: `${bedtimeDate.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')}`,
			wakeup: `${wakeupDate.toLocaleTimeString().replace(/([\d]+:[\d]{2})(:[\d]{2})(.*)/, '$1$3')}`,
			score: score
		}
	}

	const calculateImprovedUpDown = (goal, currentMin, previousMin) => {
		const improved = []
		const currentDifference = Math.abs(goal - currentMin) //How far away the user is from their goal
		const previousDifference = Math.abs(goal - previousMin)
		improved.push(currentDifference <= previousDifference) //Has the user improved - closer or further away from their goal
		//If their closer to their goal
		if (currentDifference <= previousDifference) {
			improved.push(currentMin <= goal) //true - Up: User had a later time (went to bed later), false - down: User had an earlier time (went to bed earlier)
		} else {
			improved.push(currentMin > goal)
		}
		return improved
	}

	const calculateChange = (
		stats,
		bedtimeCurrent,
		wakeupCurrent,
		durationCurrent,
		quantityCurrent,
		bedtimePrevious,
		wakeupPrevious,
		durationPrevious,
		quantityPrevious
	) => {
		const durationGoal =
			stats.bedtimeGoal < 0 ? Math.abs(stats.bedtimeGoal) + stats.wakeupGoal : stats.wakeupGoal - stats.bedtimeGoal

		const durationCurrentTotalMin = durationCurrent / quantityCurrent
		const bedtimeCurrentMin = bedtimeCurrent / quantityCurrent
		const wakeupCurrentMin = wakeupCurrent / quantityCurrent

		const durationPreviousTotalMin = durationPrevious / quantityPrevious
		const bedtimePreviousMin = bedtimePrevious / quantityPrevious
		const wakeupPreviousMin = wakeupPrevious / quantityPrevious

		const durationTotalMinDiff = Math.abs(durationCurrentTotalMin - durationPreviousTotalMin) //How many hr/min was the change in time
		const durationDiffMin = Math.round(durationTotalMinDiff % 60) === 60 ? 59 : Math.round(durationTotalMinDiff % 60)
		const durationDiffHr = Math.floor(durationTotalMinDiff / 60)

		const wakeupTotalMinDiff = Math.abs(wakeupCurrentMin - wakeupPreviousMin) //How many hr/min was the change in time
		const wakeupDiffMin = Math.round(wakeupTotalMinDiff % 60) === 60 ? 59 : Math.round(wakeupTotalMinDiff % 60)
		const wakeupDiffHr = Math.floor(wakeupTotalMinDiff / 60)

		const bedtimeTotalMinDiff = Math.abs(bedtimeCurrentMin - bedtimePreviousMin) //How many hr/min was the change in time
		const bedtimeDiffMin = Math.round(bedtimeTotalMinDiff % 60) === 60 ? 59 : Math.round(bedtimeTotalMinDiff % 60)
		const bedtimeDiffHr = Math.floor(bedtimeTotalMinDiff / 60)

		return {
			bedtime: bedtimeDiffHr === 0 ? `${bedtimeDiffMin}min` : `${bedtimeDiffHr}hr ${bedtimeDiffMin}min`,
			wakeup: wakeupDiffHr === 0 ? `${wakeupDiffMin}min` : `${wakeupDiffHr}hr ${wakeupDiffMin}min`,
			duration: durationDiffHr === 0 ? `${durationDiffMin}min` : `${durationDiffHr}hr ${durationDiffMin}min`,
			improvedUpD:
				quantityCurrent !== 0 ? calculateImprovedUpDown(durationGoal, durationCurrentTotalMin, durationPreviousTotalMin) : [], //[true, true] (Improved or not), (up or down)
			improvedUpB: quantityCurrent !== 0 ? calculateImprovedUpDown(stats.bedtimeGoal, bedtimeCurrentMin, bedtimePreviousMin) : [],
			improvedUpW: quantityCurrent !== 0 ? calculateImprovedUpDown(stats.wakeupGoal, wakeupCurrentMin, wakeupPreviousMin) : []
		}
	}

	const updateUIStats = () => {
		if (stats.type === null) return

		const noDataYet = {
			duration: '0hr 0min',
			bedtime: 'No Data',
			wakeup: 'No Data',
			score: 'N/A'
		}

		const noPreviousData = {
			wakeup: '',
			bedtime: '',
			duration: '',
			improvedUpD: [],
			improvedUpB: [],
			improvedUpW: []
		}

		const now = new Date()
		const todayDate = now.getDate()
		const todayMonth = now.getMonth() + 1
		const todayName = `${todayMonth}-${todayDate}`

		const today =
			stats.today.date === todayName
				? { ...calculateStats(stats, stats.today.bedtime, stats.today.wakeup, stats.today.duration, 1), date: stats.today.date }
				: { duration: '-', bedtime: '-', wakeup: '-', score: '-', date: '' }

		const currentWeek =
			stats.currentWeek.quantity !== 0
				? calculateStats(
						stats,
						stats.currentWeek.bedtime,
						stats.currentWeek.wakeup,
						stats.currentWeek.duration,
						stats.currentWeek.quantity
				  )
				: noDataYet

		const previousWeek =
			stats.previousWeek.quantity !== 0
				? calculateChange(
						stats,
						stats.currentWeek.bedtime,
						stats.currentWeek.wakeup,
						stats.currentWeek.duration,
						stats.currentWeek.quantity,
						stats.previousWeek.bedtime,
						stats.previousWeek.wakeup,
						stats.previousWeek.duration,
						stats.previousWeek.quantity
				  )
				: noPreviousData

		const currentMonth =
			stats.currentMonth.quantity !== 0
				? calculateStats(
						stats,
						stats.currentMonth.bedtime,
						stats.currentMonth.wakeup,
						stats.currentMonth.duration,
						stats.currentMonth.quantity
				  )
				: noDataYet

		const previousMonth =
			stats.previousMonth.quantity !== 0
				? calculateChange(
						stats,
						stats.currentMonth.bedtime,
						stats.currentMonth.wakeup,
						stats.currentMonth.duration,
						stats.currentMonth.quantity,
						stats.previousMonth.bedtime,
						stats.previousMonth.wakeup,
						stats.previousMonth.duration,
						stats.previousMonth.quantity
				  )
				: noPreviousData

		const currentQuarter =
			stats.currentQuarter.quantity !== 0
				? calculateStats(
						stats,
						stats.currentQuarter.bedtime,
						stats.currentQuarter.wakeup,
						stats.currentQuarter.duration,
						stats.currentQuarter.quantity
				  )
				: noDataYet

		const previousQuarter =
			stats.previousQuarter.quantity !== 0
				? calculateChange(
						stats,
						stats.currentQuarter.bedtime,
						stats.currentQuarter.wakeup,
						stats.currentQuarter.duration,
						stats.currentQuarter.quantity,
						stats.previousQuarter.bedtime,
						stats.previousQuarter.wakeup,
						stats.previousQuarter.duration,
						stats.previousQuarter.quantity
				  )
				: noPreviousData

		const currentYear =
			stats.currentYear.quantity !== 0
				? calculateStats(
						stats,
						stats.currentYear.bedtime,
						stats.currentYear.wakeup,
						stats.currentYear.duration,
						stats.currentYear.quantity
				  )
				: noDataYet

		const previousYear =
			stats.previousYear.quantity !== 0
				? calculateChange(
						stats,
						stats.currentYear.bedtime,
						stats.currentYear.wakeup,
						stats.currentYear.duration,
						stats.currentYear.quantity,
						stats.previousYear.bedtime,
						stats.previousYear.wakeup,
						stats.previousYear.duration,
						stats.previousYear.quantity
				  )
				: noPreviousData

		setTodayStats({
			...todayStats,
			...today
		})
		setWeekStats({
			...weekStats,
			currentWeek: currentWeek,
			previousWeek: previousWeek
		})
		setMonthStats({
			...monthStats,
			currentMonth: currentMonth,
			previousMonth: previousMonth
		})
		setQuarterStats({
			...quarterStats,
			currentQuarter: currentQuarter,
			previousQuarter: previousQuarter
		})
		setYearStats({
			...yearStats,
			currentYear: currentYear,
			previousYear: previousYear
		})
	}

	// eslint-disable-next-line
	useEffect(updateUIStats, [stats])

	//Initial state for Data
	const [chartData, setChartData] = useState({
		series: [
			{
				data: []
			}
		],
		options: {
			chart: {
				type: 'bar',
				height: 'auto',
				width: '100%',
				fontFamily: 'Roboto, Helvetica, Arial',
				background: 'transparent',
				toolbar: {
					tools: {
						download: '<img src="/images/menu.svg" width="25">'
					}
				}
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '50%',
					borderRadius: 10
				}
			},
			dataLabels: {
				enabled: false
			},
			states: {
				normal: {
					filter: {
						type: 'none',
						value: 0
					}
				},
				hover: {
					filter: {
						type: 'none',
						value: 0
					}
				},
				active: {
					allowMultipleDataPointsSelection: false,
					filter: {
						type: 'none',
						value: 0
					}
				}
			},
			fill: {
				opacity: 1
			},
			yaxis: {
				title: {
					text: 'Duration',
					style: {
						fontSize: '16px',
						fontFamily: ' Helvetica, Arial',
						fontWeight: 400
					}
				},
				showAlways: false,
				forceNiceScale: true,
				decimalsInFloat: 0,
				labels: {
					formatter: function (value) {
						return `${value}h`
					},
					style: {
						fontSize: '14px',
						fontFamily: 'Roboto, Helvetica, Arial',
						fontWeight: 400
					}
				}
			},
			xaxis: {
				categories: [],
				title: {
					text: 'Date',
					style: {
						fontSize: '18px',
						fontFamily: 'Roboto, Helvetica, Arial',
						fontWeight: 400
					}
				},
				labels: {
					trim: false,
					hideOverlappingLabels: true,
					rotate: 0,
					rotateAlways: false,
					style: {
						fontSize: '14px',
						fontFamily: 'Roboto, Helvetica, Arial',
						fontWeight: 400,
						cssClass: 'apexcharts-xaxis-label'
					}
				},
				showForNullSeries: false
			},
			tooltip: {
				enabled: true,
				followCursor: false,
				intersect: true,
				shared: false,
				fillSeriesColor: false,
				style: {
					fontSize: '14px',
					fontFamily: 'Roboto, Helvetica, Arial',
					fontWeight: 400
				},
				onDatasetHover: {
					highlightDataSeries: false
				},
				x: {
					formatter: function (val) {
						return val
					},
					show: true
				},
				y: {
					formatter: function (val) {
						return `${Math.floor(val)}h ${Math.round((val * 60) % 60)}min`
					},
					title: {
						formatter: (seriesName) => ''
					}
				},
				marker: {
					show: false
				},
				items: {
					display: 'flex'
				}
			}
		}
	})

	return (
		//props.children - represents the components that this (ContextProvider) is going to wrap
		<TracersContext.Provider
			value={{
				menuState,
				setMenuState,
				loading,
				setLoading,
				stats,
				setStats,
				todayStats,
				weekStats,
				monthStats,
				quarterStats,
				yearStats,
				timePeriod,
				setTimePeriod,
				chartData,
				setChartData
			}}
		>
			{props.children}
		</TracersContext.Provider>
	)
}

export default TracersContextProvider
