import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Typography, Card, useMediaQuery, CardContent } from '@mui/material'
import { GlobalContext } from '../../../../helpers/GlobalContext'
import { TreeItem, treeItemClasses, TreeView } from '@mui/lab'
import { TreeItemStyled } from './'
import Controls from './Controls'

const PREFIX = 'InfoBoard'
const classes = {
	card: `${PREFIX}-card`,
	controlRow: `${PREFIX}-controlRow`
}

// const CardStyled = styled(Card)(() => ({
// 	[`&.${classes.card}`]: {
// 		// display: 'flex',
// 		// overflow: 'scroll',
// 		// position: 'relative',
// 		width: '100%'
// 		//Hide scrollbar
// 		// msOverflowStyle: 'none',
// 		// '&::WebkitScrollbar': {
// 		// display: 'none'
// 		// }
// 	},
// 	[`& .${classes.controlRow}`]: {
// 		margin: 0,
// 		paddingTop: 5,
// 		paddingBottom: 5
// 	}
// }))

const TreeItemTitleStyled = styled(TreeItem)(({ theme }) => ({
	[`& .${treeItemClasses.content}`]: {
		paddingTop: '16px',
		paddingBottom: '16px',
		marginLeft: '-10px',
		cursor: 'text',
		'&:hover': {
			backgroundColor: 'transparent' //theme.palette.action.hover
		},
		'&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
			backgroundColor: 'transparent'
		},
		[`& .${treeItemClasses.label}`]: {
			...theme.typography.h3,
			textAlign: 'center'
		}
	}
}))

const TreeItemTopicVideos = ({ youtubeStats, type }) => {
	return youtubeStats[type]
		.sort((a, b) => (youtubeStats.topics[a].count <= youtubeStats.topics[b].count ? 1 : -1))
		.map((topic) => (
			<TreeItemStyled
				key={topic}
				labelText={topic}
				labelInfo={youtubeStats.topics[topic].count}
				// expanded={expanded}
				// setExpanded={setExpanded}
			>
				{youtubeStats.topics[topic].videos.length > 0 &&
					youtubeStats.topics[topic].videos.map((video) => (
						<TreeItemStyled
							key={`${topic}-${video}`}
							id={`${topic}-${video}`}
							labelImage={youtubeStats.videos[video].image}
							labelText={youtubeStats.videos[video].title}
							labelSubText={youtubeStats.videos[video].channel}
							url={youtubeStats.videos[video].url}
						/>
					))}

				{/* Add Space after last video before next topic */}
				<div style={{ height: '16px' }} />
			</TreeItemStyled>
		))
}

const getChanelName = (channelName) => {
	if (channelName === 'The Daily Show with Trevor Noah') {
		return 'The Daily Show'
	} else if (channelName === 'The Comments Section with Brett Cooper') {
		return 'The Comments Section'
	}
	return channelName
}

/** type is either count or seconds */
const TreeItemChannelVideos = ({ youtubeStats, type, formatter }) => {
	return Object.keys(youtubeStats.channels)
		.sort((a, b) => (youtubeStats.channels[a][type] <= youtubeStats.channels[b][type] ? 1 : -1))
		.slice(0, 10) //Only show top 10 channels (or less if there are less than 10)
		.map((channel) => (
			<TreeItemStyled
				key={youtubeStats.channels[channel].channelId}
				labelText={getChanelName(channel)}
				labelInfo={youtubeStats.channels[channel][type]}
				formatter={type === 'seconds' ? formatter : undefined}
				// expanded={expanded}
				// setExpanded={setExpanded}
			>
				{Object.keys(youtubeStats.videos).filter((video) => youtubeStats.videos[video].channel === channel).length > 0 &&
					Object.keys(youtubeStats.videos)
						.filter((video) => youtubeStats.videos[video].channel === channel)
						.map((video) => (
							<TreeItemStyled
								key={`${channel}-${video}`}
								id={`${channel}-${video}`}
								labelImage={youtubeStats.videos[video].image}
								labelText={youtubeStats.videos[video].title}
								labelSubText={youtubeStats.videos[video].channel}
								url={youtubeStats.videos[video].url}
							/>
						))}

				{/* Add Space after last video before next topic */}
				<div style={{ height: '16px' }} />
			</TreeItemStyled>
		))
}

const InfoBoard = ({ youtubeStats, formatter }) => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const { youtubeControlPanel } = useContext(GlobalContext)
	// const [expanded, setExpanded] = useState([])

	return (
		<Card sx={{ width: '100%', height: isDesktop ? null : 450 }}>
			<Typography variant='h3' sx={{ width: '100%', pt: 2, pb: 1, textAlign: 'center' }}>
				Top {youtubeControlPanel.view.charAt(0).toUpperCase() + youtubeControlPanel.view.slice(1)}
			</Typography>

			<Controls />

			{/* Only apply scroll properties to the Topic/Channel Tree */}
			<CardContent
				sx={{
					width: '100%',
					height: '100%',
					overflow: 'scroll',
					position: 'relative',
					// Hide scrollbar
					msOverflowStyle: 'none',
					'&::-webkit-scrollbar': {
						display: 'none'
					},
					margin: 0,
					padding: 0
				}}
			>
				<Grid
					container
					direction='column'
					alignItems={isDesktop ? 'center' : 'flex-start'}
					style={{
						width: '100%',
						paddingLeft: 8,
						paddingRight: 8,
						// paddingTop: 15,
						paddingBottom: 105, //Adjust for the hight of the controls at the top
						position: 'absolute'
					}}
				>
					{youtubeControlPanel.view === 'topics' && youtubeStats.topics && (
						<Grid item className={classes.controlRow} sx={{ width: '100%' }}>
							<TreeView aria-label={youtubeControlPanel.view} sx={{ p: 0, m: 0, overflowY: 'scroll' }}>
								<TreeItemTopicVideos type='topTopics' youtubeStats={youtubeStats} formatter={formatter} />

								<TreeItemTitleStyled nodeId='Ranking Topics' label={<Typography variant='h3'>Ranking Topics</Typography>} />

								<TreeItemTopicVideos type='rankedTopics' youtubeStats={youtubeStats} formatter={formatter} />
							</TreeView>
						</Grid>
					)}

					{youtubeControlPanel.view === 'channels' && youtubeStats.channels && (
						<Grid item className={classes.controlRow} sx={{ width: '100%' }}>
							<TreeView aria-label={youtubeControlPanel.view} sx={{ p: 0, m: 0, overflowY: 'scroll' }}>
								<TreeItemChannelVideos type={youtubeControlPanel.comparison} youtubeStats={youtubeStats} formatter={formatter} />
							</TreeView>
						</Grid>
					)}
				</Grid>
			</CardContent>
		</Card>
	)
}

export default InfoBoard
