import React, { useContext } from 'react'
import { Routes as ReactRoutes, Route, Navigate } from 'react-router-dom'
import { Main, Minimal } from './layouts'
import { setupApi } from './helpers/api'

//Components
import { MKRoute } from '@mikeint0sh/the-keeton-cloud-components/components'

//Pages
import { Login, Home, Finance, SWARM, Code, Tracers, Music, Social, Settings } from './views'
import { PageNotFound } from '@mikeint0sh/the-keeton-cloud-components/views/'

//Contexts
import { GlobalContext } from './helpers/GlobalContext'
import { MainContextProvider } from '@mikeint0sh/the-keeton-cloud-components/layouts/Main'

import TracersCP from './views/Tracers/TracersContext'
import MusicCP from './views/Music/MusicContext'

const Routes = ({ theme, tDM }) => {
	const MainLayout = { layout: Main, layoutContext: MainContextProvider, toggle: tDM }
	//For some reason the Minimal Layout needs MainContextProvider - because it has a component using GlobalContext?
	const MinimalLayout = { layout: Minimal, layoutContext: MainContextProvider, toggle: tDM }
	const { setLoggedIn } = useContext(GlobalContext)

	document.documentElement.style.setProperty('--viewport-bg', theme.palette.background)

	setupApi(setLoggedIn)

	return (
		<ReactRoutes>
			<Route path='/' element={<Navigate replace to='/Dashboard' />} />
			<Route path='/login' element={<MKRoute component={Login} {...MinimalLayout} />} />
			<Route path='/dashboard' element={<MKRoute component={Home} {...MainLayout} />} />
			<Route path='/finance' element={<MKRoute component={Finance} {...MainLayout} />} />
			<Route path='/swarm' element={<MKRoute component={SWARM} {...MainLayout} />} />
			<Route path='/code' element={<MKRoute component={Code} {...MainLayout} />} />
			<Route path='/tracers' element={<MKRoute component={Tracers} {...MainLayout} componentContext={TracersCP} />} />
			<Route path='/music' element={<MKRoute component={Music} {...MainLayout} componentContext={MusicCP} />} />
			<Route path='/social' element={<MKRoute component={Social} {...MainLayout} />} />
			<Route path='/settings' element={<MKRoute component={Settings} {...MainLayout} />} />
			<Route
				path='/404'
				element={<MKRoute component={PageNotFound} componentProps={{ colors: '#10A0FF, #F22822' }} {...MinimalLayout} />}
			/>
			<Route path='*' element={<Navigate replace to='/404' />} />
		</ReactRoutes>
	)
}

export default Routes
