import React, { useEffect, useContext, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import { ScoreSummary, TodaySummary, BarChart } from '../../components/Sleep'
import { getSleepStats as apiGetSleepStats, getSleepData as apiGetSleepData } from '../../../../helpers/api'
import { TracersContext } from '../../TracersContext'
import { GlobalContext } from '../../../../helpers/GlobalContext'
import TimePeriodStats from './TimePeriodStats'

const Sleep = () => {
	const { stats, setStats, chartData, setChartData } = useContext(TracersContext)
	const { setLoading, weekSleepData, setWeekSleepData } = useContext(GlobalContext)

	/** Get sleep stats for the week and load them into the state */
	const updateWeekSleep = async () => {
		var dataArray = []
		var categoriesArray = []

		//If the last elements date is today, load the data from local state; else get the week data from the API
		if (
			weekSleepData.categories.length !== 0 &&
			weekSleepData.categories[weekSleepData.categories.length - 1] === `${new Date().getMonth() + 1}-${new Date().getDate()}`
		) {
			dataArray = weekSleepData.data
			categoriesArray = weekSleepData.categories
		} else {
			const resultData = await apiGetSleepData('Week', null)
			// present error to user
			if (!resultData.errors) {
				dataArray = resultData.data.data.map((item) => {
					return item / 60
				})
				categoriesArray = resultData.data.date.map((item) => {
					//Dates formatted  M-D
					return `${item}`
				})
			}
			setWeekSleepData({
				data: dataArray,
				categories: categoriesArray
			})
		}
		setChartData({
			...chartData,
			series: [
				{
					data: dataArray
				}
			],
			options: {
				...chartData.options,
				xaxis: {
					...chartData.options.xaxis,
					categories: categoriesArray
				}
			}
		})
	}

	const location = useLocation()

	//Runs whenever the component mounts to populate the page with data
	//Clicking on the Tracers menu item will also run this function and check if the data is up to date
	useEffect(() => {
		//If the data is not loaded, load it; if data is not up to date, update it
		async function updateSleepStats() {
			if (stats.type === null || (weekSleepData.data.length !== 0 && weekSleepData.data[new Date().getDay()] === 0)) {
				setLoading(1)

				const resultStats = await apiGetSleepStats()
				setStats(resultStats.data)

				await updateWeekSleep()

				setLoading(-1)
			}
		}
		updateSleepStats() //Second function allows for async function

		// eslint-disable-next-line
	}, [
		location, //Runs whenever the location changes or updates ie. when the user clicks on the Tracers menu item
		// eslint-disable-next-line
		stats.type === null || (weekSleepData.data.length !== 0 && weekSleepData.data[new Date().getDay()] === 0)
	]) //Tests whenever the component remounts; runs if the data is not loaded or it's not up to date
	//}, []) //Allows the effect to only run once on mount

	return (
		<Fragment>
			<Grid item xs={12} sx={{ display: 'flex' }}>
				<TodaySummary />
			</Grid>
			<Grid item xs={12} sx={{ display: 'flex' }}>
				<ScoreSummary />
			</Grid>
			<Grid item xs={12} lg={8}>
				<BarChart />
			</Grid>
			<Grid item xs={12} lg={4} sx={{ display: 'flex' }}>
				<TimePeriodStats />
			</Grid>
		</Fragment>
	)
}

export default Sleep
