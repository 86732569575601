import React from 'react'
import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'
import { useClickUp } from '../../../../hooks'
import { TabRow } from '@mikeint0sh/the-keeton-cloud-components/components'

const Root = styled(Grid)(() => ({
	//Content: Place in all minimal layouts
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	flex: 1 //Pushes Footer to bottom
}))

const ClickUp = () => {
	const { clickupStats } = useClickUp()

	return (
		<Root item container direction='column' spacing={4}>
			<Grid item>
				{/* Watch Later Summary */}
				<TabRow
					data={[
						{
							title: 'Completed This Week',
							content: clickupStats.today ? clickupStats.today.complete - clickupStats.lastWeek.complete : undefined
						},
						{
							title: 'Completed Last Week',
							content: clickupStats.lastWeek ? clickupStats.lastWeek.complete - clickupStats.secondLastWeek.complete : undefined
						},
						{
							title: 'In Progress',
							content: clickupStats.today ? clickupStats.today.inProgress : undefined
						},
						{
							title: 'ToDo',
							content: clickupStats.today ? clickupStats.today.toDo : undefined
						}
					]}
				/>
			</Grid>
		</Root>
	)
}

export default ClickUp
