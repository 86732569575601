import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { keyframes } from '@mui/system'
import { styled } from '@mui/material/styles'
import {
	Card,
	CardContent,
	Grid,
	Typography,
	Button,
	TextField,
	FormControlLabel,
	Checkbox,
	CircularProgress
} from '@mui/material'
import { login as apiLogin } from '../../helpers/api'
import { GlobalContext } from '../../helpers/GlobalContext'
import * as GA4 from '../../helpers/GA4'
import { validator } from '@mikeint0sh/the-keeton-cloud-components/utils'

const PREFIX = 'Login'
const classes = {
	root: `${PREFIX}-root`,
	card: `${PREFIX}-card`,
	title: `${PREFIX}-title`,
	error: `${PREFIX}-error`,
	submitBtn: `${PREFIX}-submitBtn`,
	btnProgress: `${PREFIX}-btnProgress`
}

const gradientFade = keyframes`
0% {
	background-position: 0% 50%
}
50% {
	background-position: 100% 50%
}
100% {
	background-position: 0% 50%
}
`
const Root = styled('div')(({ theme: { palette } }) => ({
	[`&.${classes.root}`]: {
		height: '100%',
		background: `linear-gradient(-45deg, #02e8bb, #10A0FF, #F22822)`,
		backgroundSize: '400%',
		animation: `${gradientFade} 25s ease infinite`
	},
	[`& .${classes.card}`]: {
		maxWidth: 400,
		paddingLeft: 15,
		paddingRight: 15,
		margin: 10,
		borderRadius: 8,
		transition: '0.3s',
		boxShadow: '5px 15px 40px 2px rgba(0,0,0,0.3)',
		'&:hover': {
			boxShadow: '0px 20px 50px 3px rgba(0,0,0,0.3)'
		}
	},
	[`& .${classes.title}`]: {
		textAlign: 'center',
		fontSize: '24px',
		marginBottom: 0
	},
	[`& .${classes.error}`]: {
		fontSize: '12px',
		color: palette.secondary.main,
		textAlign: 'center',
		marginTop: '5px'
	},
	[`& .${classes.submitBtn}`]: {
		marginTop: 20,
		position: 'relative'
	},
	[`& .${classes.btnProgress}`]: {
		color: 'primary',
		position: 'absolute'
	}
}))

const Login = () => {
	const navigate = useNavigate()
	const { setLoggedIn, addCredentials, useGA } = useContext(GlobalContext)

	const [state, setState] = useState({
		email: '',
		password: '',
		rememberMe: false,
		loading: false,
		errors: {}
	})

	/**
	 * Validates email and password and sends api call to validate login and get user credentials if successful
	 * else it will set relevant errors to display to the user
	 */
	const handleSubmit = async (event) => {
		event.preventDefault() //Prevents page from reloading on submit
		setState({ ...state, loading: true }) //To display spinner
		let result = {}

		const { valid, errors } = validator.validLogin({ email: state.email, password: state.password }) //Validates the user's info
		if (valid) {
			result = await apiLogin(state.email, state.password, state.rememberMe)
		} else {
			result.errors = errors
		}

		if (!result.errors) {
			addCredentials(result.data.details)
			setLoggedIn(true)
			GA4.event(useGA, 'login', 'successful')
			navigate('/Dashboard') //Login successful
		} else {
			GA4.event(useGA, 'login', 'error', JSON.stringify(result.errors))
			setState({
				...state,
				errors: result.errors, //Set errors in local state
				loading: false //Stop loading spin weal
			})
		}
	}

	/** Updates the state when either email or password text felids change */
	const handleTextFelidChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.value
		})
	}

	/** Updates the state when either rememberMe checkbox change */
	const handleCheckboxChange = (event) => {
		setState({
			...state,
			[event.target.name]: event.target.checked
		})
	}

	return (
		<Root className={classes.root}>
			<Grid container direction='column' alignItems='center' justifyContent='center' style={{ height: '85%' }}>
				<Grid item>
					<Card className={classes.card}>
						<CardContent>
							<Typography variant='h1' className={classes.title} gutterBottom>
								Login
							</Typography>
							<form noValidate onSubmit={handleSubmit}>
								<TextField
									id='email'
									type='email'
									name='email'
									label='Email Address'
									value={state.email}
									onChange={handleTextFelidChange}
									helperText={state.errors.email}
									error={state.errors.email ? true : false}
									autoComplete='email'
									margin='normal'
									required
									fullWidth
									autoFocus
								/>
								<TextField
									id='password'
									type='password'
									name='password'
									label='Password'
									value={state.password}
									onChange={handleTextFelidChange}
									helperText={state.errors.password}
									error={state.errors.password ? true : false}
									autoComplete='current-password'
									margin='normal'
									required
									fullWidth
								/>
								<Grid container direction='row' alignItems='center' justifyContent='space-between'>
									<Grid item sm>
										<FormControlLabel
											control={
												<Checkbox
													name='rememberMe'
													value='remember'
													checked={state.rememberMe}
													onChange={handleCheckboxChange}
													color='primary'
												/>
											}
											label='Remember me'
										/>
									</Grid>
									{/* <Grid item sm style={{ textAlign: 'right' }}>
										<Link href='#' variant='body1'>
											Forgot password?
										</Link>
									</Grid> */}
								</Grid>
								{state.errors.general && (
									<Typography variant='body2' className={classes.error} align='center'>
										{state.errors.general}
									</Typography>
								)}
								<Button
									type='submit'
									fullWidth
									variant='contained'
									color='primary'
									disabled={state.loading}
									className={classes.submitBtn}
									style={{ fontSize: '16px' }}
								>
									Sign In
									{state.loading && <CircularProgress size={24} className={classes.btnProgress} />}
								</Button>
							</form>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Root>
	)
}

export default Login
