import React from 'react'
import { Typography } from '@mui/material'
import { useMusic } from '../../hooks'
import ScrobbleTile from '../Music/components/ScrobbleTile'

const Home = () => {
	const { scrobblesToday } = useMusic('scrobbles')

	return (
		<div style={{ width: '25%' }}>
			<Typography variant='h1' gutterBottom>
				Dashboard
			</Typography>
			<ScrobbleTile title='Today' count={scrobblesToday} />
		</div>
		// <Typography variant='h1' gutterBottom>
		//    Home
		// </Typography>
	)
}

export default Home
