import React, { useContext, useEffect } from 'react'
import { useTheme, Card } from '@mui/material'
import ReactApexChart from 'react-apexcharts'
import { TracersContext } from '../../TracersContext'

const BarChart = () => {
	const { palette } = useTheme()
	const { chartData, setChartData } = useContext(TracersContext)

	useEffect(() => {
		setChartData({
			...chartData,
			options: {
				...chartData.options,
				chart: {
					...chartData.options.chart,
					foreColor: palette.text.primary
				},
				colors: [palette.primary.main],
				theme: {
					mode: palette.mode
				}
			}
		})
		// eslint-disable-next-line
	}, [palette]) //Allows the effect to only run once on mount

	return (
		<Card sx={{ paddingLeft: 1, paddingRight: 2, paddingTop: 2, paddingBottom: 0 }}>
			<ReactApexChart
				options={chartData.options}
				series={chartData.series}
				type='bar'
				height='auto'
				width='100%'
				style={{ marginBottom: -10 }}
			/>
		</Card>
	)
}
export default BarChart
