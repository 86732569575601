import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChevronLeft } from '@mui/icons-material'
import { Main as MKMain } from '@mikeint0sh/the-keeton-cloud-components/layouts/Main'
import { GlobalContext } from '../helpers/GlobalContext'
import { getUserDetails as apiGetUserDetails, logout as apiLogout } from '../helpers/api'

const Main = (props) => {
	const { setLoggedIn, clearContext } = useContext(GlobalContext)
	const navigate = useNavigate() //Allows to push to different routes

	const pages = [
		{
			title: 'Dashboard',
			href: '/Dashboard',
			icon: <img alt='Dashboard' src='images/dashboard.svg' style={{ width: 31, height: 22, objectFit: 'cover' }} />
		},

		{
			title: 'The Books',
			href: '/Finance',
			icon: <img alt='Finance' src='images/bank.svg' style={{ width: 28, height: 28, objectFit: 'cover' }} />
		},
		{
			title: 'SWARM',
			href: '/Swarm',
			icon: <img alt='SWARM' src='images/tornado.svg' style={{ width: 28, height: 28, objectFit: 'cover' }} />
		},
		{
			title: 'Code Portal',
			href: '/Code',
			icon: <img alt='Code Portal' src='images/code.svg' style={{ width: 28, height: 28, objectFit: 'cover' }} />
		},
		{
			title: 'Tracers',
			href: '/Tracers',
			icon: <img alt='Tracers' src='images/tracers.svg' style={{ width: 28, height: 28, objectFit: 'cover' }} />
		},
		{
			title: 'Music',
			href: '/Music',
			icon: <img alt='Music' src='images/music.svg' style={{ width: 28, height: 28, objectFit: 'cover' }} />
		},
		// {
		// 	title: 'Social',
		// 	href: '/Social',
		// 	icon: <img alt='Social' src='images/social.svg' style={{ width: 29, height: 22, objectFit: 'cover' }} />
		// },
		{
			title: 'Settings',
			href: '/Settings',
			icon: <img alt='Settings' src='images/settings.svg' style={{ width: 28, height: 28, objectFit: 'cover' }} />
		}
	]

	const logout = async () => {
		await apiLogout()
		setLoggedIn(false)
		navigate('/login')
		clearContext()
	}

	useEffect(() => {
		console.log('On first load: Main.js - useEffect')
		// load initial data for all views
	}, [])

	return (
		<MKMain
			GlobalContext={GlobalContext}
			apiGetUserDetails={apiGetUserDetails}
			AuthorizingProps={{ image: '/images/cloud-logo.png', title: 'The Keeton Cloud' }}
			SideBarProps={{ pages: pages, BackIcon: <ChevronLeft color='secondary' /> }}
			TopBarProps={{
				menuImage: '/images/menu.svg',
				logoImage: '/images/cloud-logo.png',
				title: 'The Keeton Cloud',
				titleLink: '/Dashboard',
				toggleDarkMode: props.toggleDarkMode,
				logout: logout
			}}
			noFooter={props.noFooter}
		>
			{props.children}
		</MKMain>
	)
}

export default Main
