import React, { useContext } from 'react'
import { Grid } from '@mui/material'
import ScoreBtn from './ScoreBtn'
import { TracersContext } from '../../TracersContext'

const ScoreSummary = () => {
	const { weekStats, monthStats, quarterStats, yearStats, timePeriod } = useContext(TracersContext)

	return (
		<Grid container direction='row' justifyContent='center'>
			<Grid item xs={6} sm={3}>
				<ScoreBtn
					title='Week'
					percent={weekStats.currentWeek.score === 'N/A' ? -1 : weekStats.currentWeek.score}
					active={timePeriod === 'Week'}
				/>
			</Grid>

			<Grid item xs={6} sm={3}>
				<ScoreBtn
					title='Month'
					percent={monthStats.currentMonth.score === 'N/A' ? -1 : monthStats.currentMonth.score}
					active={timePeriod === 'Month'}
				/>
			</Grid>

			<Grid item xs={6} sm={3} sx={{ marginTop: { xs: 3, sm: 0 } }}>
				<ScoreBtn
					title='Quarter'
					percent={quarterStats.currentQuarter.score === 'N/A' ? -1 : quarterStats.currentQuarter.score}
					active={timePeriod === 'Quarter'}
				/>
			</Grid>

			<Grid item xs={6} sm={3} sx={{ marginTop: { xs: 3, sm: 0 } }}>
				<ScoreBtn
					title='Year'
					percent={yearStats.currentYear.score === 'N/A' ? -1 : yearStats.currentYear.score}
					active={timePeriod === 'Year'}
				/>
			</Grid>
		</Grid>
	)
}

export default ScoreSummary
