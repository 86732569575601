import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'
import { FixedTopBar } from '@mikeint0sh/the-keeton-cloud-components/components'
import { Sleep, YouTube, ClickUp, MenuBar } from './components'
import { GlobalContext } from '../../helpers/GlobalContext'
import { TracersContext } from './TracersContext'

const PREFIX = 'Tracers'
const classes = {
	root: `${PREFIX}-root`,
	content: `${PREFIX}-content`
}

const Root = styled('div')(() => ({
	[`&.${classes.root}`]: {
		// flex: 1,
		// display: 'flex'
	},
	[`& .${classes.content}`]: {
		height: '100%',
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		paddingTop: '5px',
		paddingLeft: '5px',
		paddingRight: '5px'
	}
}))

const Tracers = () => {
	const { menuState } = useContext(TracersContext)

	return (
		<Root className={classes.root}>
			<FixedTopBar side='top' height={50} GlobalContext={GlobalContext}>
				<MenuBar height={50} />

				<div className={classes.content}>
					{menuState === 'sleep' && (
						<Grid container spacing={4} justifyContent='center'>
							<Sleep />
						</Grid>
					)}
					{menuState === 'youtube' && (
						<Grid container direction='column' spacing={4} justifyContent='center'>
							<YouTube />
						</Grid>
					)}
					{menuState === 'clickup' && (
						<Grid container direction='column' spacing={4} justifyContent='center'>
							<ClickUp />
						</Grid>
					)}
					{/* <Footer /> */}
				</div>
			</FixedTopBar>
		</Root>
	)
}

export default Tracers
