import React from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Grid, Button, Card, Grow, Skeleton, useMediaQuery } from '@mui/material'

const PREFIX = 'MusicTile'
const classes = {
	card: `${PREFIX}-card`,
	button: `${PREFIX}-button`,
	title: `${PREFIX}-title`,
	root: `${PREFIX}-root`
}

const Root = styled('div')(({ theme: { palette } }) => ({
	[`&.${classes.root}`]: {
		flex: 1
	},
	[`& .${classes.card}`]: {
		width: '100%',
		height: '100%',
		aspectRatio: '1 / 1', //Automatically sets the height equal to the width
		borderRadius: 0,
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center'
	},
	[`& .${classes.button}`]: {
		width: '100%',
		height: '100%',
		transition: '0.35s',
		backgroundColor: '#00000000',
		//Set hover opaque black background over image to help readability of white text
		'&:hover': {
			borderRadius: 0,
			backgroundColor: '#00000080'
		}
	},
	[`& .${classes.title}`]: {
		paddingLeft: 5,
		color: palette.white,
		textAlign: 'center'
	}
}))

const MusicTile = ({ tab, music }) => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const [hover, setHover] = React.useState(false)

	/** Redirects to Spotify for the selected music */
	const openLink = () => {
		window.open(music.url, '_blank')
	}

	return (
		<Root className={classes.root}>
			{!music && (
				<Skeleton
					variant='rectangular'
					animation='wave'
					sx={{ height: 0, position: 'relative', overflow: 'hidden', paddingTop: '100%', backgroundColor: 'tile' }}
				/>
			)}
			{music && (
				<Card className={classes.card} style={{ backgroundImage: `url(${music.image})` }}>
					<Button
						onClick={openLink}
						onMouseEnter={() => setHover(true)}
						onMouseLeave={() => setHover(false)}
						className={classes.button}
						style={
							!isDesktop && tab === 'tracks'
								? {
										borderRadius: 0,
										backgroundColor: '#00000080'
								  }
								: {}
						}
					>
						<Grow in={hover || (!isDesktop && tab === 'tracks')} timeout={400} unmountOnExit>
							<Grid
								container
								direction='column'
								justifyContent='center'
								alignItems='center'
								// style={{ height: '100%', width: '100%' }}
							>
								<Grid item xs>
									<Typography variant='h3' className={classes.title}>
										{music.name}
									</Typography>
								</Grid>
								{tab !== 'artists' && (
									<Grid item xs>
										<Typography variant='h4' className={classes.title}>
											{music.artist}
										</Typography>
									</Grid>
								)}
							</Grid>
						</Grow>
					</Button>
				</Card>
			)}
		</Root>
	)
}

export default MusicTile
