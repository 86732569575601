import React from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Typography, Box } from '@mui/material'
import { TreeItem, treeItemClasses } from '@mui/lab'

const TreeItemRootStyled = styled(TreeItem)(({ theme }) => ({
	color: theme.palette.text.secondary,
	[`& .${treeItemClasses.content}`]: {
		color: theme.palette.text.secondary,
		paddingRight: 0, //theme.spacing(1),
		marginLeft: -25,
		'&.Mui-expanded': {
			fontWeight: theme.typography.fontWeightRegular
		},
		'&:hover': {
			backgroundColor: 'transparent' //theme.palette.action.hover
		},
		'&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
			backgroundColor: 'transparent', //`var(--tree-view-bg-color, ${theme.palette.action.selected})`,
			// color: 'transparent' //'var(--tree-view-color)'
			'&:hover': {
				backgroundColor: 'transparent' //theme.palette.action.hover
			}
		},
		[`& .${treeItemClasses.label}`]: {
			fontWeight: 'inherit',
			color: 'inherit'
		}
	}
}))

// 	expanded,setExpanded,
const TreeItemStyled = ({ id, labelImage, labelText, labelSubText, labelInfo, url, formatter, ...other }) => {
	const openLink = () => {
		if (url) {
			window.open(url, '_blank')
		}
	}
	return (
		<TreeItemRootStyled
			label={
				<Box onClick={openLink} sx={{ display: 'flex', alignItems: 'center', p: 0.5, px: 0, mr: -2 }}>
					{labelImage && (
						<Box sx={{ width: '142px', height: '80px', mr: 1 }}>
							<img
								alt={labelText}
								src={labelImage}
								style={{ width: 142, height: 80, objectFit: 'cover', margin: 8, marginLeft: 0 }}
							/>
						</Box>
					)}
					<Grid
						container
						spacing={0.5}
						direction='column'
						justifyContent='flex-start'
						sx={labelSubText ? { height: '90px', p: '10px', pl: '5px', flexGrow: 1 } : { flexGrow: 1 }}
					>
						<Grid item>
							<Typography
								variant={!labelImage ? 'h4' : 'body2'}
								sx={{
									display: '-webkit-box',
									overflow: 'hidden',
									WebkitBoxOrient: 'vertical',
									WebkitLineClamp: 2,
									fontWeight: 'inherit'
								}}
							>
								{labelText}
							</Typography>
						</Grid>

						{labelSubText && (
							<Grid item>
								<Typography
									variant='subtitle2'
									color='inherit'
									sx={{
										display: '-webkit-box',
										overflow: 'hidden',
										WebkitBoxOrient: 'vertical',
										WebkitLineClamp: 1,
										fontWeight: 'inherit'
									}}
								>
									{labelSubText}
								</Typography>
							</Grid>
						)}
					</Grid>
					{labelInfo && (
						<Typography variant='body1' color='inherit'>
							{formatter ? formatter(labelInfo) : labelInfo}
						</Typography>
					)}
				</Box>
			}
			nodeId={id ?? labelText}
			// style={{
			// 	padding: 0,
			// 	margin: 0
			// 	// '--tree-view-color': 'transparent',
			// 	// '--tree-view-bg-color': 'transparent'
			// }}
			// onClick={() => {
			// 	if (setExpanded) {
			// 		if (expanded.length > 0 && expanded[0] === (id ?? labelText)) {
			// 			setExpanded([])
			// 		} else {
			// 			setExpanded([id ?? labelText])
			// 		}
			// 	}
			// }}
			{...other}
			// endIcon={<div style={{ padding: 0, margin: 0, maxWidth: 10, height: 10, backgroundColor: '#10a0ff' }} />}
		/>
	)
}

export default TreeItemStyled
