import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { AppBar, Toolbar, Typography, Grid, Link, Hidden, useMediaQuery } from '@mui/material'
import { GlobalContext } from '../../../../helpers/GlobalContext'
import * as GA4 from '../../../../helpers/GA4'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const PREFIX = 'TopBar'
const classes = {
	root: `${PREFIX}-root`,
	title: `${PREFIX}-title`,
	menuItemText: `${PREFIX}-menuItemText`
}

const StyledAppBar = styled(AppBar)(({ theme: { palette } }) => ({
	[`& .${classes.root}`]: {
		boxShadow: 'none',
		backgroundColor: palette.menu
	},
	[`& .${classes.title}`]: {
		fontWeight: 300,
		paddingLeft: 15
	},
	[`& .${classes.menuItemText}`]: {
		fontWeight: 300,
		borderBottom: `1px solid`,
		marginBottom: -1,
		'&:hover': {
			color: palette.success.main,
			borderBottomColor: palette.success.main
		}
	}
}))

const TopBar = ({ className, ...rest }) => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const { useGA } = useContext(GlobalContext)

	return (
		<StyledAppBar {...rest} className={clsx(classes.root, className)} position='fixed'>
			<Toolbar className={classes.root} sx={{ height: '64px' }}>
				<Grid container direction='row' justifyContent='flex-start' alignItems='center'>
					<img
						alt='Logo'
						src='/images/cloud-logo.png'
						style={{ width: 55, height: 35, objectFit: 'contain', marginLeft: !isDesktop ? 10 : -2 }}
					/>
					<Typography className={classes.title} variant='h2'>
						The Keeton Cloud
					</Typography>

					<Hidden smDown>
						<Grid item style={{ flexGrow: 1 }}></Grid>
						<Link
							href='https://finance.keeton.cloud'
							rel='noopener'
							component='a'
							style={{ textDecoration: 'none' }}
							onClick={() => {
								GA4.event(useGA, 'Finance_link')
							}}
							underline='hover'
						>
							<Typography className={classes.menuItemText} variant='h3'>
								Finance
							</Typography>
						</Link>
					</Hidden>
				</Grid>
			</Toolbar>
		</StyledAppBar>
	)
}

TopBar.propTypes = {
	className: PropTypes.string
}

export default TopBar
