import React, { useEffect, useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Hidden } from '@mui/material'
import { MusicTile, ScrobbleTile } from './components'
import { MusicContext } from './MusicContext'
import { useMusic } from '../../hooks'

const PREFIX = 'MusicTilesView'
const classes = {
	root: `${PREFIX}-root`,
	scoreBtn: `${PREFIX}-scoreBtn`,
	tile: `${PREFIX}-tile`
}

const Root = styled('div')(({ theme: { breakpoints } }) => ({
	[`&.${classes.root}`]: {
		flex: 1,
		//Undo the margin of the Main Layout to place music grig op to the edges
		marginTop: -20,
		marginLeft: -20,
		marginRight: -20,
		[breakpoints.only('xs')]: {
			marginTop: -16,
			marginLeft: -16,
			marginRight: -16
		}
	},
	[`& .${classes.scoreBtn}`]: {
		marginTop: 0,
		[breakpoints.down('xs')]: {
			marginTop: 35
		}
	},
	[`& .${classes.tile}`]: {
		flex: 1
	}
}))

const MusicTilesView = () => {
	const { musicState } = useContext(MusicContext)

	const { topMusicCharts, scrobblesToday } = useMusic('all')

	const diffInMs = new Date() - new Date('12-17-2014')
	const daysSinceStart = parseInt(diffInMs / (1000 * 60 * 60 * 24))
	const keys = { today: 1, '7day': 7, '1month': 30, '3month': 90, '6month': 180, '12month': 365, overall: daysSinceStart }

	useEffect(() => {
		window.scrollTo(0, 0) //Ensures page loads at top
	}, [])

	return (
		<Root className={classes.root}>
			{/* Show top 10 grid once data has loaded */}
			<Grid container spacing={0} direction='column' justifyContent='flex-start' alignItems='center'>
				<Grid item container spacing={0} direction='row' justifyContent='center' alignItems='center'>
					<Grid item xs={6} md={3} lg={2}>
						<ScrobbleTile title='Today' count={scrobblesToday} />
					</Grid>

					<Hidden lgUp>
						<Grid item xs={6} md={3} lg={2}>
							<ScrobbleTile
								title='Average'
								count={
									topMusicCharts.scrobbles === -1 ? -1 : parseInt(topMusicCharts.scrobbles / keys[topMusicCharts.timePeriod])
								}
							/>
						</Grid>
					</Hidden>

					<Grid item xs={6} md={3} lg={2}>
						<MusicTile tab={musicState.tab} music={topMusicCharts[musicState.tab][0]} />
					</Grid>

					<Grid item xs={6} md={3} lg={2}>
						<MusicTile tab={musicState.tab} music={topMusicCharts[musicState.tab][1]} />
					</Grid>

					<Grid item xs={6} md={3} lg={2}>
						<MusicTile tab={musicState.tab} music={topMusicCharts[musicState.tab][2]} />
					</Grid>

					<Grid item xs={6} md={3} lg={2}>
						<MusicTile tab={musicState.tab} music={topMusicCharts[musicState.tab][3]} />
					</Grid>

					<Grid item xs={6} md={3} lg={2}>
						<MusicTile tab={musicState.tab} music={topMusicCharts[musicState.tab][4]} />
					</Grid>

					<Hidden lgDown>
						<Grid item xs={6} md={3} lg={2}>
							<ScrobbleTile
								title='Average'
								count={
									topMusicCharts.scrobbles === -1 ? -1 : parseInt(topMusicCharts.scrobbles / keys[topMusicCharts.timePeriod])
								}
							/>
						</Grid>
					</Hidden>

					<Grid item xs={6} md={3} lg={2}>
						<MusicTile tab={musicState.tab} music={topMusicCharts[musicState.tab][5]} />
					</Grid>

					<Grid item xs={6} md={3} lg={2}>
						<MusicTile tab={musicState.tab} music={topMusicCharts[musicState.tab][6]} />
					</Grid>

					<Grid item xs={6} md={3} lg={2}>
						<MusicTile tab={musicState.tab} music={topMusicCharts[musicState.tab][7]} />
					</Grid>

					<Grid item xs={6} md={3} lg={2}>
						<MusicTile tab={musicState.tab} music={topMusicCharts[musicState.tab][8]} />
					</Grid>

					<Grid item xs={6} md={3} lg={2}>
						<MusicTile tab={musicState.tab} music={topMusicCharts[musicState.tab][9]} />
					</Grid>
				</Grid>
			</Grid>
		</Root>
	)
}

export default MusicTilesView
