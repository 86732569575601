import React from 'react'
import * as d3 from 'd3'

/**
 * https://www.pluralsight.com/guides/using-d3.js-inside-a-react-app
 * @param {*} renderChartFn is a callback that contains your D3.js code to be executed
 * @param {Array} dependencies is a fixed-length array to tell React when to run the renderChartFn.
 */
const useD3 = (renderChartFn, dependencies) => {
	const ref = React.useRef()

	React.useEffect(() => {
		//Render a d3 chart within React
		renderChartFn(d3.select(ref.current))
		return () => {}
		// eslint-disable-next-line
	}, dependencies)

	return ref
}

export default useD3
