import { useContext, useEffect } from 'react'
import { GlobalContext } from '../helpers/GlobalContext'
import { getBudgetOverview as apiGetBudgetOverview, getRecentTransactions as apiGetRecentTransactions } from '../helpers/api'

const useFinance = () => {
	const { activeBudget, setActiveBudget, setRecentTransactions, setLoading, setSiteAlert } = useContext(GlobalContext)
	//Check that we have fetched the needed data, if not, fetch it

	const handelCreateTransaction = () => {
		window.open('https://finance.keeton.cloud/transactions?create=true', '_blank')
	}

	const handleError = (error) => {
		setTimeout(() => {
			setSiteAlert({
				//Shows a message at the top of the site
				title: 'Error Loading Current Budget',
				description: `${error}`,
				variant: 'standard',
				severity: 'error',
				actionBtn: '',
				handleActionBtn: () => {},
				showMessage: true
			})
		}, 500)
	}

	/** Fetch this month's budget */
	const fetchBudget = async () => {
		//Get the name of the current budget
		const today = new Date()
		const currentYear = today.getFullYear()
		const currentMonth = today.getMonth() + 1

		//If we have already fetched the data, don't fetch it again
		if (
			activeBudget.name !== `${currentMonth}-${currentYear}` &&
			Object.entries(activeBudget.budget).length === 0 &&
			activeBudget.budget.constructor === Object
		) {
			setLoading(1)
			const result = await apiGetBudgetOverview()
			if (result.errors) {
				console.error('Error hooks/useFinance fetchBudget():', result.errors)
				handleError(result.errors) //TODO: Format error
			} else {
				const budget = result.data.currentMonth
					? { name: `${currentMonth}-${currentYear}`, type: 'Current', budget: result.data.currentMonth }
					: { name: '', type: 'Current', budget: {} }

				setActiveBudget(budget)
			}
			setLoading(-1)
		}
	}

	const fetchRecentTransactions = async () => {
		setLoading(1)
		const result = await apiGetRecentTransactions()

		if (result.errors) {
			console.error('Error hooks/useFinance fetchRecentTransactions():', result.errors)
			handleError(result.errors) //TODO: Format error
		} else {
			setRecentTransactions(result.data)
		}
		setLoading(-1)
	}

	/** Runs on first load of the Hook to load all music data and setup Today's scrobbles refresh */
	useEffect(() => {
		//Fetch budget data on first load
		fetchBudget()
		fetchRecentTransactions()
		// const interval = setInterval(() => {
		// 	console.log('Interval Scrobbles update')
		// 	fetchScrobblesToday() //Don't show loading indicator on refresh
		// }, 120000) // 2 * 60 * 1000 = Every 2 minutes refresh
		// return () => clearInterval(interval) //Cleanup function stops the interval when the component is unmounted
		// eslint-disable-next-line
	}, [])

	return { handelCreateTransaction }
}

export default useFinance
