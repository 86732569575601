import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Card, Typography } from '@mui/material'
import { GlobalContext } from '../../helpers/GlobalContext'
import { accounting } from '@mikeint0sh/the-keeton-cloud-components/utils'

const PREFIX = 'AccountDetails'
const classes = {
	card: `${PREFIX}-card`,
	accountRow: `${PREFIX}-accountRow`,
	gridItem: `${PREFIX}-gridItem`
}

const CardStyled = styled(Card)(() => ({
	[`&.${classes.card}`]: {
		width: '100%',
		padding: 10,
		paddingTop: 15,
		paddingBottom: 15
	},
	[`& .${classes.accountRow}`]: {
		width: '100%',
		margin: 0,
		padding: 5
	},
	[`&.${classes.gridItem}`]: {
		margin: 0
	}
}))

const AccountDetails = ({ physicalAccounts }) => {
	const { accounts } = useContext(GlobalContext)

	return (
		<CardStyled className={classes.card}>
			<Grid container direction='column' alignItems='center' sx={{ display: 'flex', height: '100%' }}>
				<Grid item sx={{ paddingBottom: '10px' }}>
					<Typography variant='h3'>Accounts</Typography>
				</Grid>

				{accounts.map((physicalAccountArr) => {
					//Show all virtual accounts except for those unassigned
					if (physicalAccountArr[0].physicalAccountID !== 'unassigned') {
						return physicalAccountArr.map((account, accountIndex) => {
							if (accountIndex !== 0) {
								return (
									<Grid item container direction='row' className={classes.accountRow} key={account.virtualAccountID}>
										<Grid item>
											<Typography variant='h5' sx={{ fontWeight: 300, fontSize: 18, paddingLeft: '17px' }}>
												{account.name}
											</Typography>
										</Grid>
									</Grid>
								)
							} else {
								return (
									<Grid item container direction='row' className={classes.accountRow} key={account.physicalAccountID}>
										<Grid item key={account.physicalAccountID}>
											{/* TODO: Mach font to the left */}
											<Typography variant='h4' sx={{ fontSize: 21, paddingTop: '8px' }}>
												{account.name}
											</Typography>
										</Grid>

										{physicalAccounts[account.physicalAccountID] && (
											<Grid item>
												<Typography variant='h4' sx={{ fontSize: 19, paddingLeft: '17px', paddingTop: '8px' }}>
													{accounting.format(physicalAccounts[account.physicalAccountID])}
												</Typography>
											</Grid>
										)}
									</Grid>
								)
							}
						})
					}
					return null //Hide unassigned virtual accounts
				})}
			</Grid>
		</CardStyled>
	)
}

export default AccountDetails
