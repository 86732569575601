import React from 'react'
import { styled } from '@mui/material/styles'
import MusicTilesView from './MusicTilesView'
import { FixedTopBar } from '@mikeint0sh/the-keeton-cloud-components/components'
import { GlobalContext } from '../../helpers/GlobalContext'

import { MenuBar } from './components'

const PREFIX = 'Music'
const classes = {
	root: `${PREFIX}-root`,
	content: `${PREFIX}-content`
}

const Root = styled('div')(() => ({
	[`&.${classes.root}`]: {
		flex: 1,
		display: 'flex'
	},
	[`& .${classes.content}`]: {
		//Place in all minimal layouts
		height: '100%',
		flexGrow: 1,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
		// position: 'absolute',
		// marginTop: -114,
		// paddingTop: 114
	}
}))

const Music = () => {
	return (
		<Root className={classes.root}>
			<FixedTopBar side='top' height={50} GlobalContext={GlobalContext}>
				<MenuBar height={50} />

				<div className={classes.content}>
					<MusicTilesView />
					{/* <Footer /> */}
				</div>
			</FixedTopBar>
		</Root>
	)
}

export default Music
