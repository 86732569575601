import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Card, Grid, useMediaQuery } from '@mui/material'
import { Tab, TabContent } from '@mikeint0sh/the-keeton-cloud-components/components'
import { TracersContext } from '../../TracersContext'

const PREFIX = 'TodaySummary'
const classes = {
	card: `${PREFIX}-card`,
	grid: `${PREFIX}-grid`,
	title: `${PREFIX}-title`,
	content: `${PREFIX}-content`,
	root: `${PREFIX}-root`
}

const Root = styled('div')(({ theme: { breakpoints } }) => ({
	[`&.${classes.root}`]: {
		flex: 1
	},
	[`& .${classes.card}`]: {
		padding: 10,
		paddingRight: 0
	},
	[`& .${classes.grid}`]: {
		marginTop: 10,
		marginBottom: 10
	},
	[`& .${classes.title}`]: {
		paddingLeft: 5,
		paddingBottom: 10,
		textAlign: 'center'
	},
	[`& .${classes.content}`]: {
		textAlign: 'center',
		fontWeight: 400,
		[breakpoints.down('xs')]: {
			fontSize: 30
		}
	}
}))

const TodaySummary = () => {
	const { todayStats } = useContext(TracersContext)
	const isXs = useMediaQuery(({ breakpoints }) => breakpoints.only('xs'), { defaultMatches: true })

	return (
		<Root className={classes.root}>
			<Card className={classes.card}>
				<Grid container direction='row' className={classes.grid}>
					<Tab divider>
						{/* weekStats.currentWeek.bedtime */}
						<TabContent title='Bedtime' content={todayStats.bedtime} />
					</Tab>

					<Tab divider={!isXs}>
						<TabContent title='Wakeup' content={todayStats.wakeup} />
					</Tab>

					<Tab divider margin={isXs}>
						<TabContent title='Duration' content={todayStats.duration} />
					</Tab>

					<Tab margin={isXs}>
						<TabContent title='Score' content={todayStats.score === 0 ? '' : `${todayStats.score}%`} />
					</Tab>
				</Grid>
			</Card>
		</Root>
	)
}

export default TodaySummary
