import { useContext, useEffect } from 'react'
import { GlobalContext } from '../helpers/GlobalContext'
import { getClickupStats as apiGetClickupStats } from '../helpers/api'

const useClickUp = () => {
	const { clickupStats, setClickupStats, setLoading, setSiteAlert } = useContext(GlobalContext)

	const handleError = (error) => {
		setTimeout(() => {
			setSiteAlert({
				//Shows a message at the top of the site
				title: 'Error Loading ClickUp Data',
				description: `${error}`,
				variant: 'standard',
				severity: 'error',
				actionBtn: '',
				handleActionBtn: () => {},
				showMessage: true
			})
		}, 500)
	}

	/** Fetch the YouTube Watch Later stats from the database */
	const fetchStats = async () => {
		setLoading(1)

		const result = await apiGetClickupStats()
		if (result.errors) {
			console.error('Error hooks/useClickUp fetchStats():', result.errors)
			handleError(result.errors) //TODO: Format error
		} else {
			setClickupStats(result.data)
		}

		setLoading(-1)
	}

	/** Runs on first load of the Hook to load all ClickUp data - refreshes stats whenever user returns to screen */
	useEffect(() => {
		fetchStats()
		// eslint-disable-next-line
	}, [])

	return { clickupStats }
}

export default useClickUp
