import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { AppBar, Toolbar, Grid, Button, Typography } from '@mui/material'
import { TracersContext } from '../TracersContext'

const PREFIX = 'MenuBar'
const classes = {
	appBar: `${PREFIX}-appBar`,
	toolbar: `${PREFIX}-toolbar`,
	menuBtn: `${PREFIX}-menuBtn`,
	menuText: `${PREFIX}-menuText`,
	dropDown: `${PREFIX}-dropDown`
}

const StyledAppBar = styled(AppBar)(({ theme: { palette, breakpoints } }) => ({
	[`&.${classes.appBar}`]: {
		boxShadow: 'none',
		zIndex: 1000, //Above the Site Alert but it will move down for it
		backgroundColor: palette.menu,
		flexGrow: 1,
		display: 'flex'
	},
	[`& .${classes.toolbar}`]: {
		color: palette.secondary,
		backgroundColor: palette.menu //Fixes root background color being the wrong color
	},
	[`& .${classes.menuBtn}`]: {
		border: 0,
		margin: 0,
		paddingLeft: 10,
		paddingRight: 10,
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
			backgroundColor: 'transparent'
		},
		[breakpoints.down('sm')]: {
			paddingLeft: 7,
			paddingRight: 7
		}
	},
	[`& .${classes.menuText}`]: {
		fontWeight: 300,
		paddingTop: 2,
		fontSize: 19,
		'&:hover': {
			borderBottom: `1px solid`,
			color: palette.secondary.main,
			borderBottomColor: palette.secondary.main,
			marginBottom: -1
		}
	},
	[`& .${classes.dropDown}`]: {
		fontWeight: 300,
		paddingTop: 5,
		paddingLeft: 10,
		fontSize: 19,
		width: '152px',
		'&:hover': {
			color: palette.secondary.main
		}
	}
}))

const MenuBar = (props) => {
	const { menuState, setMenuState } = useContext(TracersContext)
	/** Updates the state when the music type (tab) changes */
	const handleMenuChoice = (event) => {
		window.scrollTo(0, 0) //Ensures view loads at top
		const name = event.currentTarget.name
		setMenuState(name) //Set the new value
	}

	return (
		<StyledAppBar
			component='menu'
			position='static'
			className={classes.appBar}
			sx={{ height: { xs: props.height - 10, sm: props.height } }}
		>
			<Toolbar
				component='div'
				variant='dense'
				disableGutters={true}
				className={classes.toolbar}
				sx={{ height: { xs: props.height - 10, sm: props.height } }}
			>
				<Grid container direction='row' justifyContent='center' alignItems='center'>
					<Button color='secondary' onClick={handleMenuChoice} className={classes.menuBtn} name='sleep'>
						<Typography
							color={menuState === 'sleep' ? 'secondary' : 'textPrimary'}
							className={classes.menuText}
							variant='h4'
							sx={{ paddingLeft: { xs: '4px', sm: 0 } }}
						>
							Sleep
						</Typography>
					</Button>

					<Button color='secondary' onClick={handleMenuChoice} className={classes.menuBtn} name='youtube'>
						<Typography color={menuState === 'youtube' ? 'secondary' : 'textPrimary'} className={classes.menuText} variant='h4'>
							YouTube
						</Typography>
					</Button>

					<Button color='secondary' onClick={handleMenuChoice} className={classes.menuBtn} name='clickup'>
						<Typography color={menuState === 'clickup' ? 'secondary' : 'textPrimary'} className={classes.menuText} variant='h4'>
							ClickUp
						</Typography>
					</Button>
				</Grid>
			</Toolbar>
		</StyledAppBar>
	)
}

export default MenuBar
