import axios from 'axios'
import { getStartEndDates } from '../helpers/services'

/** Prefixes all api calls with the base ural and tells the browser to send along the cookie with the token */
const api = axios.create({
	withCredentials: true,
	baseURL: 'https://api.keeton.cloud/'
})

export const setupApi = (setLoggedIn) => {
	axios.defaults.headers.post['cache-control'] = 'private'

	api.interceptors.response.use(
		(response) => {
			setLoggedIn(true)
			return response
		},
		(error) => {
			//Run after every api call to check for authorization
			if (error.response && error.response.status === 401) {
				setLoggedIn(false)
			} else {
				setLoggedIn(true)
			}
			return Promise.reject(error)
		}
	)
}

/** Logs in a user or sends errors */
export const login = async (email, password, rememberMe) => {
	const result = {}
	try {
		const res = await api.post('login', { email, password, rememberMe })
		result.data = res.data
	} catch (err) {
		if (err) {
			result.errors = err.response ? err.response.data : err //Sends along errors to UI (email not valid, can not be blank, wrong credentials, etc.)
		} else {
			result.errors = { general: 'Something went wrong, please try again' }
		}
	}
	return result
}

export const logout = async () => {
	const result = {}
	try {
		const res = await api.post('logout')
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}

/** Get user details */
export const getUserDetails = async () => {
	const result = {}
	try {
		const res = await api.get('user')
		const { userId, firstName, lastName, email, username, image } = res.data.details
		result.data = { userId, firstName, lastName, email, username, image } //Sent back username, profile photo, email and user id
	} catch (err) {
		result.errors = err.response ? err.response : err
	}
	return result
}

/** Get sleep stats */
export const getSleepStats = async () => {
	const result = {}
	try {
		const res = await api.get('sleep/stats')
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response : err
	}
	return result
}

/** Get YouTube stats */
export const getYoutubeStats = async () => {
	const result = {}
	try {
		const res = await api.get('youtube/stats')
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response : err
	}
	return result
}

/** Get Clickup stats */
export const getClickupStats = async () => {
	const result = {}
	try {
		const res = await api.get('clickup/stats')
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response : err
	}
	return result
}

/** Get sleep data for a given date range */
export const getSleepData = async (timePeriod, dates) => {
	const result = {}

	try {
		if (dates === null) {
			dates = getStartEndDates(timePeriod)
		}
		const res = await api.get(`sleep/${dates.startDate}/to/${dates.endDate}`)
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response : err
	}

	return result
}

/** Get music charts for the given type (artists/tracks/albums)*/
export const getMusicCharts = async (type, timePeriod) => {
	const result = {}
	try {
		const res = await api.get(`music/${type}?period=${timePeriod}`)
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response : err
	}
	return result
}

/** Get music charts for the given type (artists/tracks/albums)*/
export const getMusicScrobbleCount = async (days) => {
	const result = {}
	try {
		const res = await api.get(`music/scrobbles/${days}`)
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response : err
	}
	return result
}

/** Finance API */
const financeApi = axios.create({
	withCredentials: true,
	baseURL: 'https://finance.keeton.cloud/api/'
})

/** Get Finance Accounts data */
export const getAccounts = async () => {
	const result = {}
	try {
		const res = await financeApi.get('accounts')
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}

/** Get Budget Overview data */
export const getBudgetOverview = async () => {
	const result = {}
	try {
		const res = await financeApi.get('budget/overview')
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}

/** Get Recent Transaction data */
export const getRecentTransactions = async () => {
	const result = {}
	try {
		const res = await financeApi.get('transactions')
		result.data = res.data
	} catch (err) {
		result.errors = err.response ? err.response.data : err
	}
	return result
}
