import React from 'react'
import { styled } from '@mui/material/styles'
import { Grid } from '@mui/material'
import { getAccounts as apiGetAccounts } from '../../helpers/api'
import { GlobalContext } from '../../helpers/GlobalContext'
import { AccountsSummary, BudgetView, TransactionTable, useAccounts } from '@mikeint0sh/the-keeton-cloud-components/finance'
import useFinance from '../../hooks/useFinance'
import AccountDetails from './AccountDetails'

const Root = styled('div')(() => ({
	//Content: Place in all minimal layouts
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	flex: 1 //Pushes Footer to bottom
}))

const GridStyled = styled(Grid)(() => ({ display: 'flex' }))

const Finance = () => {
	const { handelCreateTransaction } = useFinance() //Loads BudgetView data on load
	const { accountsSummary, physicalAccounts } = useAccounts('accountsSummary', apiGetAccounts, GlobalContext)

	return (
		<Root>
			<Grid container spacing={4} justifyContent='flex-start'>
				<GridStyled item xs={12}>
					<AccountsSummary accountsSummary={accountsSummary} />
				</GridStyled>

				<Grid item xs={12} spacing={4} container justifyContent='flex-start'>
					<GridStyled item sm={12} md={8}>
						<BudgetView toggleBalance GlobalContext={GlobalContext} />
					</GridStyled>

					<GridStyled item sm={12} md={4} container>
						<AccountDetails physicalAccounts={physicalAccounts} />
					</GridStyled>
				</Grid>

				<GridStyled item xs={12}>
					<TransactionTable GlobalContext={GlobalContext} handelCreateTransaction={handelCreateTransaction} />
				</GridStyled>
			</Grid>
		</Root>
	)
}

export default Finance
