import React, { useContext } from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Card, Grid, Typography, Button } from '@mui/material'
import ProgressCircle from '../../../../helpers/ProgressCircle'
import { TracersContext } from '../../TracersContext'
import { GlobalContext } from '../../../../helpers/GlobalContext'
import { getSleepData as apiGetSleepData } from '../../../../helpers/api'
import { getStartEndDates } from '../../../../helpers/services'

const PREFIX = 'ScoreBtn'
const classes = {
	card: `${PREFIX}-card`,
	title: `${PREFIX}-title`,
	root: `${PREFIX}-root`,
	circleProgress: `${PREFIX}-circleProgress`,
	scoreFont: `${PREFIX}-scoreFont`
}

const Root = styled('div')(({ theme: { breakpoints } }) => ({
	[`& .${classes.card}`]: {
		paddingRight: 10,
		paddingLeft: 10,
		paddingTop: 15,
		borderRadius: 16, //25
		[breakpoints.up('xs')]: {
			height: 145,
			width: 145
		},
		[breakpoints.up('sm')]: {
			height: 120,
			width: 120
		},
		[breakpoints.up('md')]: {
			height: 130,
			width: 130
		},
		[breakpoints.up('lg')]: {
			height: 210,
			width: 210
		},
		[breakpoints.up('xl')]: {
			height: 300,
			width: 300
		}
	},
	[`& .${classes.title}`]: {
		paddingLeft: 5,
		textAlign: 'center'
	},
	[`&.${classes.root}`]: {
		flex: 1
	},
	//Defines the size of the Progress Circle
	[`& .${classes.circleProgress}`]: {
		marginTop: 5,
		[breakpoints.up('xs')]: {
			height: 100,
			width: 100,
			marginTop: 0
		},
		[breakpoints.up('sm')]: {
			height: 80,
			width: 80,
			marginTop: 0
		},
		[breakpoints.up('md')]: {
			height: 90,
			width: 90,
			marginTop: 0
		},
		[breakpoints.up('lg')]: {
			height: 145,
			width: 145
		},
		[breakpoints.up('xl')]: {
			height: 180,
			width: 180,
			marginTop: 10
		}
	},
	//Defines the font of the typography inside the progress circle
	[`& .${classes.scoreFont}`]: {
		fontWeight: 400,
		[breakpoints.up('xs')]: {
			fontSize: 44
		},
		[breakpoints.up('sm')]: {
			fontSize: 38
		},
		[breakpoints.up('md')]: {
			fontSize: 42
		},
		[breakpoints.up('lg')]: {
			fontSize: 54
		},
		[breakpoints.up('xl')]: {
			fontSize: 58
		}
	}
}))

const ScoreBtn = (props) => {
	const { palette } = useTheme()
	const { setTimePeriod, chartData, setChartData } = useContext(TracersContext)
	const { setLoading, weekSleepData, allSleepData, setAllSleepData } = useContext(GlobalContext)

	const getBeginningData = async (dates, timePeriod) => {
		//First Missing Data
		const memoryStart = allSleepData.categories[0] //Get the first date in memory
		const memoryStartParts = memoryStart.split('-')
		//Get the date that we need to get more data up to
		const apiFirstEnd = new Date(
			new Date().getFullYear(), //parseInt(memoryStartParts[2]),
			parseInt(memoryStartParts[0] - 1),
			parseInt(memoryStartParts[1]),
			0,
			0,
			0,
			0
		)
		apiFirstEnd.setDate(apiFirstEnd.getDate() - 1) //Get the last date that comes before what is already in memory
		const resultData = await apiGetSleepData(timePeriod, {
			startDate: dates.startDate,
			endDate: `${apiFirstEnd.getMonth() + 1}-${apiFirstEnd.getDate()}-${apiFirstEnd.getFullYear()}`
		})
		var data = []
		var categories = []

		data = resultData.data.data.map((item) => {
			return item / 60
		})
		categories = resultData.data.date

		return { data, categories }
	}

	const getEndingData = async (dates, timePeriod) => {
		//Second Missing Data
		const localEnd = allSleepData.categories[allSleepData.categories.length - 1] //Get the last date in memory
		const localEndParts = localEnd.split('-')
		//Get the date that we need to start getting more data at
		const apiSecondStart = new Date(
			new Date().getFullYear(), //parseInt(localEndParts[2]),
			parseInt(localEndParts[0] - 1),
			parseInt(localEndParts[1]),
			0,
			0,
			0,
			0
		)
		apiSecondStart.setDate(apiSecondStart.getDate() + 1) //Get the date after what is already in memory

		const resultData = await apiGetSleepData(timePeriod, {
			startDate: `${apiSecondStart.getMonth() + 1}-${apiSecondStart.getDate()}-${apiSecondStart.getFullYear()}`,
			endDate: dates.endDate
		})

		var data = []
		var categories = []

		// present error to user

		if (!resultData.errors) {
			data = resultData.data.data.map((item) => {
				return item / 60
			})
			categories = resultData.data.date
		}

		return { data, categories }
	}

	const changeTimePeriod = async (timePeriod) => {
		setLoading(1)
		setTimePeriod(timePeriod)

		const dates = getStartEndDates(timePeriod) //Get the start and end dates for the requested time period
		const startDate = allSleepData.categories.indexOf(dates.startDate.split('-').slice(0, 2).join('-')) //Index of the first date in the requested time period
		const endDate = allSleepData.categories.indexOf(dates.endDate.split('-').slice(0, 2).join('-')) //Index of the last date in the requested time period
		var dataArray = []
		var categoriesArray = []
		let barRadius = 0 //Border radius for the top of the bars
		switch (timePeriod) {
			case 'Week':
				barRadius = 10
				break
			case 'Month':
				barRadius = 3
				break
			case 'Quarter':
				barRadius = 1
				break
			default:
				//Year
				barRadius = 0
				break
		}

		if (timePeriod === 'Week') {
			//Load data from memory
			dataArray = weekSleepData.data
			categoriesArray = weekSleepData.categories
		} else if (allSleepData.data.length === 0) {
			//If there is no data at all in memory get data like normal
			const resultData = await apiGetSleepData(timePeriod, null)
			dataArray = resultData.data.data.map((item) => {
				return item / 60
			})
			categoriesArray = resultData.data.date

			setAllSleepData({
				data: dataArray,
				categories: categoriesArray
			})
		} else if (startDate !== -1 && endDate !== -1) {
			//We have all the data for this call already in memory
			dataArray = allSleepData.data.slice(startDate, endDate + 1) //Push just the data requested to the chart
			categoriesArray = allSleepData.categories.slice(startDate, endDate + 1) //Same for categories
		} else {
			//We have some of the data for this call already in memory but not all of it, so fetch the missing parts
			//If the start and end dates are not in memory (ie. have just February in Q1)
			if (startDate === -1 && endDate === -1) {
				//We have data in the middle (ie. have February in Q1 or have Q3 in the year) to get -> [First Missing Data] + [Data in Memory] + [Second Missing Data]
				const beginningData = await getBeginningData(dates, timePeriod)
				// item / 60
				dataArray = [...beginningData.data, ...allSleepData.data] //Add the first part of data to the beginning of what we already have (ie. [First Missing Data] + [Data in Memory])
				categoriesArray = [...beginningData.categories, ...allSleepData.categories] //Same for categories

				const endingData = await getEndingData(dates, timePeriod)
				// item / 60
				dataArray.push(...endingData.data) //Add the second part of data to the end of what we already have (ie.  [Data in Memory] + [Second Missing Data])
				categoriesArray.push(...endingData.categories) //Same for categories
			} else if (startDate !== -1 && endDate === -1) {
				//We have data at the start (ie. have January in Q1 or having Q1 out of the year) to get -> [Data in Memory] + [Second Missing Data]
				const endingData = await getEndingData(dates, timePeriod)
				// item / 60
				dataArray = [...allSleepData.data.slice(startDate), ...endingData.data] //Add the last part of data to the beginning of what we already have (ie. [Data in Memory] + [Missing Data])
				categoriesArray = [...allSleepData.categories.slice(startDate), ...endingData.categories] //Same for categories
			} else {
				//We have data at the end (ie. have March in Q1 or having Q4 out of the year) to get -> [First Missing Data] + [Data in Memory]
				const beginningData = await getBeginningData(dates, timePeriod)
				// item / 60
				dataArray = [...beginningData.data, ...allSleepData.data] //Add the first part of data to the beginning of what we already have (ie. [First Missing Data] + [Data in Memory])
				categoriesArray = [...beginningData.categories, ...allSleepData.categories] //Same for categories
			}

			//Add the data to memory
			setAllSleepData({
				data: dataArray,
				categories: categoriesArray
			})
		}
		setChartData({
			...chartData,
			series: [
				{
					data: dataArray
				}
			],
			options: {
				...chartData.options,
				xaxis: {
					...chartData.options.xaxis,
					categories: categoriesArray,
					labels: {
						...chartData.options.xaxis.labels,
						formatter: (value) => {
							if (typeof value === 'number') {
								value = value.toString()
							}
							//Finally the magic bullet: \u3164 is the HANGUL FILLER in unicode which is blank (appearing as a space)
							//This allows us to have a gap between the x-axis labels so they don't overlap!
							return `${value} \u3164`
						}
					}
				},
				plotOptions: {
					...chartData.options.plotOptions,
					bar: {
						...chartData.options.plotOptions.bar,
						borderRadius: barRadius
					}
				},
				tooltip: {
					...chartData.options.tooltip,
					// add all the following
					followCursor: timePeriod !== 'Week',
					intersect: timePeriod === 'Week',
					shared: timePeriod !== 'Week'
				}
			}
		})
		setLoading(-1)
		dataArray = []
		categoriesArray = []
	}

	return (
		<Root className={classes.root}>
			<Grid container justifyContent='center' alignItems='center'>
				<Button
					disableRipple
					onClick={() => {
						changeTimePeriod(props.title)
					}}
					style={{ backgroundColor: 'transparent' }}
				>
					<Grid item>
						<Card
							className={classes.card}
							style={
								props.active
									? {
											backgroundImage: `radial-gradient(circle, ${palette.mode === 'dark' ? '#333333' : '#FFF'}, ${
												palette.mode === 'dark' ? palette.background : '#dddddf'
											}`
									  }
									: {}
							}
						>
							<Grid container direction='column' alignItems='center' justifyContent='space-between' style={{ height: '100%' }}>
								<Grid item xs>
									<ProgressCircle
										percent={props.percent}
										strokeWidth={6}
										strokeColor='#10A0FF'
										trailColor={`${palette.mode === 'dark' ? `${palette.background}ee` : '#ccccccee'}`}
										trailWidth={6}
										rootClass={classes.circleProgress}
										textClass={classes.scoreFont}
										duration={1.2}
									/>
								</Grid>
								<Grid item xs container direction='row' justifyContent='center' alignItems='center'>
									<Typography variant='subtitle1' className={classes.title}>
										{props.title}
									</Typography>
								</Grid>
							</Grid>
						</Card>
					</Grid>
				</Button>
			</Grid>
		</Root>
	)
}

export default ScoreBtn
