//https://observablehq.com/@f16ea6bbcc9f4cf7/covid-19-bubble-chart-with-d3-render
//https://bl.ocks.org/HarryStevens/54d01f118bc8d1f2c4ccd98235f33848
import React from 'react'
import * as d3 from 'd3'
import { useD3 } from '../../../../hooks'
import { Fragment } from 'react'
import { Typography } from '@mui/material'

/** Encodes string id's to hex to ensure id is free of special chars ie. ' $ , etc. that are not allowed in selectors */
// eslint-disable-next-line
String.prototype.hexEncode = function () {
	var hex, i

	var result = ''
	for (i = 0; i < this.length; i++) {
		hex = this.charCodeAt(i).toString(16)
		result += ('000' + hex).slice(-4)
	}

	return result
}

const BubbleChart = ({ data, formatter }) => {
	const width = 400
	const height = 400

	const [state, setState] = React.useState('') // stores the title of hovering bubble

	const ref = useD3(
		(svg) => {
			//Packs the data into bubbles
			var pack = d3.pack().size([width, height]).padding(2)

			draw(data) //Draw svg with initial data

			/** Draws the svg with the provided data */
			function draw(data) {
				//Transition
				var myTransition = d3.transition().duration(1000)

				//Hierarchy - create the root node with a value equal to the sum of all children elements' value
				var root = pack(d3.hierarchy({ children: data }).sum((d) => d.value)).leaves()

				//JOIN - Join new data in the elements
				var circle = svg.selectAll('circle').data(root, (d) => d.data.id.hexEncode())
				var image = svg.selectAll('image').data(root, (d) => d.data.id.hexEncode())
				var overlay = svg.selectAll('overlay').data(root, (d) => d.data.id.hexEncode())
				var text = svg.selectAll('text').data(root, (d) => d.data.id.hexEncode())

				//EXIT - attributes to apply before transition, after transition, and then remove
				//Fix - loads all the data on first load even if it is not visible and then don't remove it
				circle.exit().transition(myTransition).attr('opacity', 0.25).attr('r', 0) //.remove()
				image.exit().transition(myTransition).attr('r', 0) // or comment out all together
				overlay.exit().transition(myTransition).attr('opacity', 0).remove()
				text.exit().transition(myTransition).attr('opacity', 0).style('font-size', '1px').remove()

				//UPDATE - attributes to apply when the data changes but was already there and isn't being removed
				circle
					.transition(myTransition) //Adjust to the new dimensions
					.attr('r', (d) => d.r)
					.attr('cx', (d) => d.x)
					.attr('cy', (d) => d.y)

				image
					.transition(myTransition) //Adjust to the new dimensions
					.attr('width', (d) => d.r * 2 + 2) //+2 Fixes fringing on the edges
					.attr('height', (d) => d.r * 2 + 2) //+2 Fixes fringing on the edges
					.attr('x', (d) => d.x - d.r - 1) //-1 Fixes fringing on the edges
					.attr('y', (d) => d.y - d.r - 1) //-1 Fixes fringing on the edges

				overlay
					.transition(myTransition) //Adjust to the new dimensions
					.attr('r', (d) => d.r)
					.attr('cx', (d) => d.x)
					.attr('cy', (d) => d.y)
					.attr('x', (d) => d.x)
					.attr('y', (d) => d.y)

				text
					.transition(myTransition) //Adjust to the new dimensions
					.text((d) => {
						//Recalculate the text based on the new radius - TODO: make this more dynamic (font size)
						const value = formatter ? formatter(d.data.value) : d.data.value
						if (`${value}`.length * 6.7 < d.r * 2) {
							return value
						}
						return ''
					})
					.attr('x', (d) => d.x)
					.attr('y', (d) => d.y + 5)

				//ENTER
				circle
					.enter()
					.append('defs') //Defs are used to store patterns
					.append('svg:clipPath') //Clip path is used to clip the image to a circle
					.attr('id', (d) => 'chanel_image_' + d.data.id.hexEncode()) //Identify the clip path
					.append('circle') //Clip path
					.attr('id', (d) => 'clip_circle_' + d.data.id.hexEncode())
					.attr('r', 0) //Set the radius to 0 so it can be animated later
					.attr('cx', width / 2) //Start from the center of the svg
					.attr('cy', height / 2)
					.transition(myTransition) //Adjust to the correct dimensions
					.attr('cx', (d) => d.x)
					.attr('cy', (d) => d.y)
					.attr('r', (d) => d.r)

				//Clip Image http://jsfiddle.net/fppsru68/1/
				image
					.enter()
					.append('svg:image')
					.attr('xlink:href', (d) => d.data.image)
					.attr('width', 0)
					.attr('height', 0)
					.attr('x', width / 2)
					.attr('y', height / 2)
					.attr('clip-path', (d) => `url(#chanel_image_${d.data.id.hexEncode()})`)
					// .append('circle')
					// .attr('r', (d) => d.r)
					// .attr('cx', (d) => d.x)
					// .attr('cy', (d) => d.y)
					// .style('fill', '#FFFFFF')
					.transition(myTransition) //Adjust to the correct dimensions
					.attr('r', (d) => d.r)
					.attr('x', (d) => d.x - d.r - 1) //-1 Fixes fringing on the edges
					.attr('y', (d) => d.y - d.r - 1) //-1 Fixes fringing on the edges
					.attr('width', (d) => d.r * 2 + 2) //+2 Fixes fringing on the edges
					.attr('height', (d) => d.r * 2 + 2) //+2 Fixes fringing on the edges

				//Overlay over the image on hover to make the text more legible; if image is undefined: topic - the background circle
				overlay
					.enter()
					.append('circle')
					// .attr('r', 0)
					.attr('cx', width / 2) //Start from the center of the svg
					.attr('cy', height / 2)
					//If there is an image, start out transparent; else it is for a topic and fill with the topic color
					.style('fill', (d) => (d.data.image ? '#00000000' : d.data.fill)) // TODO: make different colors for popularity of topic
					.attr('opacity', (d) => (d.data.image ? 0 : 1)) //If topic (no image), always show color (its the background)
					.on('mouseover', (e, d) => {
						if (d.data.image) {
							//Show dark overlay on hover
							d3.select(e.target).transition().duration(400).style('fill', '#00000080').attr('opacity', 1) //.call(draw)
							d3.select(`#chanel_title_${d.data.id.hexEncode()}`).transition().duration(400).attr('opacity', 1)
							d3.select(`#chanel_value_${d.data.id.hexEncode()}`).transition().duration(400).attr('opacity', 1)
						}
						//Raise the text to top (over the overlay/bg color)
						d3.select(`#chanel_title_${d.data.id.hexEncode()}`).raise()
						d3.select(`#chanel_value_${d.data.id.hexEncode()}`).raise()
						setState(d.data.name) //Set the name of the topic/chanel
					})
					.on('mouseout', (e, d) => {
						if (d.data.image) {
							//Hide the overlay on hover
							d3.select(e.target).transition().duration(400).style('fill', '#00000000').attr('opacity', 0)
							d3.select(`#chanel_title_${d.data.id.hexEncode()}`).transition().duration(400).attr('opacity', 0)
							d3.select(`#chanel_value_${d.data.id.hexEncode()}`).transition().duration(400).attr('opacity', 0)
						}
						setState('')
					})
					.transition(myTransition) //Adjust to the correct dimensions
					.attr('cx', (d) => d.x)
					.attr('cy', (d) => d.y)
					.attr('r', (d) => d.r)

				//Title text
				text
					.enter()
					.append('text')
					.attr('id', (d) => 'chanel_title_' + d.data.id.hexEncode())
					.attr('opacity', 0)
					.attr('x', width / 2) //Start from the center of the svg
					.attr('y', height / 2)
					.text((d) => {
						//If the chanel name fits in the circle, show it
						const value = formatter ? formatter(d.data.value) : d.data.value
						if (d.data.name.length * 7 < d.r * 2) {
							return d.data.name
						} else if (`${value}`.length * 7 < d.r * 2) {
							//Else show the value if it fits
							return value
						}
						return '' //If it's too small for both, don't show anything
					})
					.style('text-anchor', 'middle')
					.style('font-family', 'Roboto')
					.style('font-size', '1px') //Start off small; animate to correct size
					.style('fill', '#ffffff')
					.style('pointer-events', 'none')
					.transition(myTransition) //Adjust to the correct dimensions
					.attr('opacity', (d) => (d.data.image ? 0 : 1)) //Show on hover if (channel) image; else always show (topic) value
					.style('font-size', '12px')
					.attr('x', (d) => d.x)
					.attr('y', (d) => {
						//If the chanel name fits in the circle, show it in the center
						if (d.data.name.length * 7 < d.r * 2) {
							return d.y
						}
						//Else shift it down a bit
						return d.y + 5
					})

				//Value text
				text
					.enter()
					.append('text')
					.attr('id', (d) => 'chanel_value_' + d.data.id.hexEncode())
					.attr('opacity', 0)
					.attr('x', width / 2) //Start from the center of the svg
					.attr('y', height / 2)
					.text((d) => {
						//If the chanel name fits in the circle, also show the value
						if (d.data.name.length * 7 < d.r * 2) {
							const value = formatter ? formatter(d.data.value) : d.data.value
							return value
						}
						return '' //Else don't show the value here because it's already shown in the title (if it fits)
					})
					.style('text-anchor', 'middle')
					.style('font-family', 'Roboto')
					.style('font-size', '1px') //Start off small; animate to correct size
					.style('fill', '#ffffff')
					.style('pointer-events', 'none')
					.transition(myTransition) //Adjust to the correct dimensions
					.attr('opacity', (d) => (d.data.image ? 0 : 1)) //Show on hover if (channel) image; else always show (topic) value
					.style('font-size', '11px')
					.attr('x', (d) => d.x)
					.attr('y', (d) => d.y + 13) //Shift it down a bit
			}
		},
		[data]
	)

	return (
		<Fragment>
			<Typography variant='h4' sx={{ p: 1.5, pt: 1, height: '30px' }}>
				{state}
			</Typography>
			<svg
				ref={ref}
				//Allows to scale in the parent component without an exact size
				preserveAspectRatio='xMidYMid meet'
				viewBox={`0 0 ${width} ${height}`}
				style={{
					marginTop: '-10px',
					marginRight: '5px',
					marginLeft: '5px'
				}}
			/>
		</Fragment>
	)
}

export default BubbleChart
