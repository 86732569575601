import React, { useContext, Fragment } from 'react'
import { styled } from '@mui/material/styles'
import { Card, Grid, useMediaQuery, Divider, Typography, useTheme } from '@mui/material'
import { TracersContext } from '../../TracersContext'
import { Tab } from '@mikeint0sh/the-keeton-cloud-components/components'

const PREFIX = 'TimePeriodStats'
const classes = {
	card: `${PREFIX}-card`,
	grid: `${PREFIX}-grid`,
	root: `${PREFIX}-root`
}

const Root = styled('div')(() => ({
	[`&.${classes.root}`]: {
		width: '100%'
	},
	[`& .${classes.card}`]: {
		display: 'flex',
		minHeight: '100%',
		paddingLeft: 10,
		paddingRight: 5
	},
	[`& .${classes.grid}`]: {
		flex: 1,
		minHeight: '100%',
		paddingTop: 10,
		paddingBottom: 10,
		paddingLeft: 5,
		paddingRight: 0
	}
}))

const TitleTypography = styled(Typography)(({ theme: { breakpoints } }) => ({
	paddingLeft: 5,
	paddingBottom: 10,
	fontSize: 18,
	textAlign: 'center',
	[breakpoints.down('xs')]: {
		fontSize: 14,
		lineHeight: 0.6
	}
}))

const ContentTypography = styled(Typography)(({ theme: { breakpoints } }) => ({
	textAlign: 'center',
	fontSize: 35,
	fontWeight: 400,
	[breakpoints.down('xs')]: {
		fontSize: 20,
		lineHeight: 1
	}
}))

const TimePeriodStats = () => {
	const { breakpoints, palette } = useTheme()
	const { timePeriod, weekStats, monthStats, quarterStats, yearStats } = useContext(TracersContext)
	const isMd = useMediaQuery(breakpoints.only('md'), { defaultMatches: true })

	const getStats = (timing) => {
		switch (timePeriod) {
			case 'Week':
				return timing === 'current' ? weekStats.currentWeek : weekStats.previousWeek

			case 'Month':
				return timing === 'current' ? monthStats.currentMonth : monthStats.previousMonth

			case 'Quarter':
				return timing === 'current' ? quarterStats.currentQuarter : quarterStats.previousQuarter

			case 'Year':
				return timing === 'current' ? yearStats.currentYear : yearStats.previousYear

			default:
				return timing === 'current' ? weekStats.currentWeek : weekStats.previousWeek
		}
	}

	function StatContainer(props) {
		return (
			<Grid item xs={3} md={4} sx={{ minWidth: '100%' }}>
				<Grid
					container
					direction='column'
					justifyContent='center'
					alignItems='center'
					sx={{ py: { xs: 1.5, md: 0 }, height: '100%' }}
				>
					{props.children}
				</Grid>
				{props.divider && (
					<Grid item>
						<Divider orientation='horizontal' sx={{ backgroundColor: palette.secondary.main, marginTop: { xs: 0, md: 1 } }} />
					</Grid>
				)}
			</Grid>
		)
	}

	function Stat(props) {
		return (
			<Fragment>
				<Grid item container direction='row' justifyContent='center' alignItems='center' sx={{ width: '100%' }}>
					<Grid item>
						<TitleTypography variant='subtitle1'>{props.title}</TitleTypography>
					</Grid>
					{props.improvedUp.length > 0 && (
						<Fragment>
							<Grid item>
								{props.improvedUp[0] && (
									<img
										alt='Increase'
										src={`images/chevron-${props.improvedUp[1] ? 'up' : 'down'}-green.svg`}
										style={{ height: 10, objectFit: 'cover', marginBottom: 12, marginLeft: 10, marginRight: -3 }}
									/>
								)}
								{!props.improvedUp[0] && (
									<img
										alt='Increase'
										src={`images/chevron-${props.improvedUp[1] ? 'up' : 'down'}-red.svg`}
										style={{ height: 10, objectFit: 'cover', marginBottom: 12, marginLeft: 10, marginRight: -3 }}
									/>
								)}
							</Grid>

							<Grid item>
								<TitleTypography variant='subtitle1'>{props.change}</TitleTypography>
							</Grid>
						</Fragment>
					)}
				</Grid>
				<Grid item xs={props.small ? 12 : null}>
					<ContentTypography variant='h1'>
						<Root>
							{props.averageTime === '' && '\u3164'} {/* Space character \u3164 */}
							{props.averageTime !== '' && props.averageTime}
						</Root>
					</ContentTypography>
				</Grid>
			</Fragment>
		)
	}

	return (
		<Root className={classes.root}>
			<Card className={classes.card}>
				{!isMd && (
					<Grid container direction='column' className={classes.grid}>
						<StatContainer divider>
							<Stat
								title='Bedtime'
								averageTime={getStats('current').bedtime}
								change={getStats('previous').bedtime}
								improvedUp={getStats('previous').improvedUpB}
							/>
						</StatContainer>

						<StatContainer divider>
							<Stat
								title='Wakeup'
								averageTime={getStats('current').wakeup}
								change={getStats('previous').wakeup}
								improvedUp={getStats('previous').improvedUpW}
							/>
						</StatContainer>

						<StatContainer>
							<Stat
								title='Duration'
								averageTime={getStats('current').duration}
								change={getStats('previous').duration}
								improvedUp={getStats('previous').improvedUpD}
							/>
						</StatContainer>
					</Grid>
				)}
				{isMd && (
					<Grid container direction='row' className={classes.grid}>
						<Tab quantity={3} divider>
							<Stat
								small
								title='Bedtime'
								averageTime={getStats('current').bedtime}
								change={getStats('previous').bedtime}
								improvedUp={getStats('previous').improvedUpB}
							/>
						</Tab>

						<Tab quantity={3} divider>
							<Stat
								small
								title='Wakeup'
								averageTime={getStats('current').wakeup}
								change={getStats('previous').wakeup}
								improvedUp={getStats('previous').improvedUpW}
							/>
						</Tab>

						<Tab quantity={3}>
							<Stat
								small
								title='Duration'
								averageTime={getStats('current').duration}
								change={getStats('previous').duration}
								improvedUp={getStats('previous').improvedUpD}
							/>
						</Tab>
					</Grid>
				)}
			</Card>
		</Root>
	)
}

export default TimePeriodStats
