import React, { createContext, useState, useEffect } from 'react'

export const GlobalContext = createContext()

const GlobalContextProvider = (props) => {
	//Initial state
	const [credentials, setCredentials] = useState({
		firstName: '',
		lastName: '',
		email: '',
		username: '',
		userId: '',
		image: ''
	})

	const [loggedIn, setLoggedIn] = useState(false)
	const [useGA, setUseGA] = useState(true) //Uses Google Analytics or not
	const [siteAlert, setSiteAlert] = useState({
		//Shows a message at the top of the site
		title: 'Title',
		description: 'This is the description for the alert',
		variant: 'standard', //standard, outlined
		severity: 'info', //info, success, warning, error
		actionBtn: '', //Optional title for action button
		handleActionBtn: () => {}, //Optional function to run when action button is clicked
		showMessage: false
	})
	const [loading, setLoadingState] = useState(false) //Shows the loading indicator in the TopBar when loadingCount > 0
	const [loadingCount, setLoadingCount] = useState(0) //Sets the number of components that are loading
	const setLoading = (value) => {
		setLoadingCount((previousLoadingCount) => previousLoadingCount + value) //Adds or subtracts from the loadingCount
	}
	useEffect(() => {
		if (loadingCount > 0) {
			setLoadingState(true)
		} else {
			setLoadingState(false)
		}
	}, [loadingCount])

	/** Clears all data in Global Context - for when the user logs out */
	const clearContext = () => {
		setCredentials({ firstName: '', lastName: '', email: '', username: '', userId: '', image: '' })
		setSiteAlert({
			title: '',
			description: '',
			variant: 'standard',
			severity: 'info',
			actionBtn: '',
			handleActionBtn: () => {},
			showMessage: false
		})
		setLoadingCount(0)
		setWeekSleepData({ data: [], categories: [] })
		setAllSleepData({ data: [], categories: [] })
		setTopMusicCharts({ timePeriod: '1month', artists: [], tracks: [], albums: [], scrobbles: -1 })
		setScrobblesToday(-1)
	}

	const [weekSleepData, setWeekSleepData] = useState({
		data: [],
		categories: []
	})

	const [allSleepData, setAllSleepData] = useState({
		data: [],
		categories: []
	})

	//{ today, lastWeek, secondLastWeek } each has { onHold, toDo, inProgress, complete }
	const [clickupStats, setClickupStats] = useState({})

	//topics: { topic: { count, videos: [ videoId, ] } }   //, channels: [ chanelId, ]
	//videos: { id: { title, url, image, chanel } }  // id is from the url
	//channels: { name: { channelId, url, image, count, seconds } }
	//playlistStats: { average_video_length, days_to_watch, total_time, total_videos }
	const [youtubeStats, setYoutubeStats] = useState({})

	const [youtubeControlPanel, setYoutubeControlPanel] = useState({
		view: 'channels', //channels, topics
		comparison: 'seconds' //seconds, count
	})

	const [topMusicCharts, setTopMusicCharts] = useState({
		timePeriod: '7day', //Data for time period (7day, 1month, 3month, 6month, 12month or overall)
		artists: [],
		tracks: [],
		albums: [],
		scrobbles: -1
	})

	const [scrobblesToday, setScrobblesToday] = useState(-1) // scrobblesToday

	const addCredentials = (item) => {
		setCredentials({ ...credentials, ...item }) //Only overwrites the values that are in item
	}

	/** Finance Data */
	//Local storage for Accounts data gathered from Firebase - [ [index 0: the physical account. followed by it's virtual accounts], [{Phy.Acc.}, {V-Acc.}], [...]]
	const [accounts, setAccounts] = useState([])
	//Local storage for Transactions data gathered from Firebase - [ {Transaction}, {Transaction} ]
	const [recentTransactions, setRecentTransactions] = useState([])
	//The budget activity shown to the user from allBudgets
	const [activeBudget, setActiveBudget] = useState({ name: '', type: '', budget: {} })
	//Control Panel for viewing a budget
	const [budgetViewControlPanel, setBudgetViewControlPanel] = useState({
		sectionProgress: false,
		categoryProgress: false,
		budgetedBW: false,
		spentBW: false,
		surplusDeficitBW: true,
		accountBalanceBW: true,
		budgetedS: false,
		savedS: true,
		spentS: false,
		goalS: false,
		accountBalanceS: true
	})

	return (
		//props.children - represents the components that this (ContextProvider) is going to wrap
		<GlobalContext.Provider
			value={{
				loggedIn,
				setLoggedIn,
				credentials,
				addCredentials,
				loading,
				setLoading,
				clearContext,
				weekSleepData,
				setWeekSleepData,
				allSleepData,
				setAllSleepData,
				clickupStats,
				setClickupStats,
				youtubeStats,
				setYoutubeStats,
				youtubeControlPanel,
				setYoutubeControlPanel,
				useGA,
				setUseGA,
				siteAlert,
				setSiteAlert,
				topMusicCharts,
				setTopMusicCharts,
				scrobblesToday,
				setScrobblesToday,
				accounts,
				setAccounts,
				recentTransactions,
				setRecentTransactions,
				activeBudget,
				setActiveBudget,
				budgetViewControlPanel,
				setBudgetViewControlPanel
			}}
		>
			{props.children}
		</GlobalContext.Provider>
	)
}

export default GlobalContextProvider
