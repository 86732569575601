import { useContext, useEffect, useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { GlobalContext } from '../helpers/GlobalContext'
import { getYoutubeStats as apiGetYoutubeStats } from '../helpers/api'

const useYouTube = () => {
	const { youtubeStats, setYoutubeStats, youtubeControlPanel, setLoading, setSiteAlert } = useContext(GlobalContext)
	const [bubbleData, setBubbleData] = useState([])
	const { palette } = useTheme()

	const handleError = (error) => {
		setTimeout(() => {
			setSiteAlert({
				//Shows a message at the top of the site
				title: 'Error Loading YouTube Data',
				description: `${error}`,
				variant: 'standard',
				severity: 'error',
				actionBtn: '',
				handleActionBtn: () => {},
				showMessage: true
			})
		}, 500)
	}

	/** Fetch the YouTube Watch Later stats from the database */
	const fetchStats = async () => {
		setLoading(1)

		const result = await apiGetYoutubeStats()
		if (result.errors) {
			console.error('Error hooks/useYouTube fetchStats():', result.errors)
			handleError(result.errors) //TODO: Format error
		} else {
			setYoutubeStats(result.data)
		}

		setLoading(-1)
	}

	const getBubbleData = () => {
		let data = []

		if (youtubeControlPanel.view === 'channels') {
			data = Object.keys(youtubeStats.channels).map((channel) => {
				return {
					name: channel,
					id: youtubeStats.channels[channel].channelId,
					value: youtubeStats.channels[channel][youtubeControlPanel.comparison],
					fill: palette.tile,
					image: youtubeStats.channels[channel].image,
					url: youtubeStats.channels[channel].url
				}
			})
		} else {
			//topics
			data = Object.keys(youtubeStats.topics).map((topic) => {
				return {
					name: topic,
					id: topic,
					value: youtubeStats.topics[topic]['count'],
					fill: '#10a0ff', //palette.tile,
					// image: 'https://metrocatholicoutreach.org/wp-content/uploads/layerslider/LayerSlider-5-responsive-demo-slider/bg.jpg',
					url: 'https://metrocatholicoutreach.org/wp-content/uploads/layerslider/LayerSlider-5-responsive-demo-slider/bg.jpg'
				}
			})
			// const excludes = ['Linus Tech Tips', 'LaterClips', 'Ben Shapiro', 'WatchMojo.com', 'TechLinked', 'Techquickie', 'CNBC']

			// data = Object.keys(youtubeStats.channels)
			// 	.map((channel) => {
			// 		return {
			// 			name: channel,
			// 			id: youtubeStats.channels[channel].channelId,
			// 			value: youtubeStats.channels[channel][youtubeControlPanel.comparison],
			// 			fill: palette.tile,
			// 			image: youtubeStats.channels[channel].image,
			// 			url: youtubeStats.channels[channel].url
			// 		}
			// 	})
			// 	.filter((a) => (!excludes.includes(a.name) ? a : null))
		}

		const excludes = [] //['Linus Tech Tips', 'LaterClips', 'Ben Shapiro', 'WatchMojo.com', 'TechLinked', 'Techquickie', 'CNBC']
		return data.sort((a, b) => b.value - a.value).filter((a) => (!excludes.includes(a.name) ? a : null))
	}

	/** Takes seconds (int || string) and returns a time-code formatted 'd:h' */
	const formatSeconds = (time) => {
		let timeCode = ''
		const totalSeconds = parseInt(time)

		const days = Math.floor(totalSeconds / 86400)
		const hours = Math.floor((totalSeconds % 86400) / 3600)
		const minutes = Math.floor((totalSeconds % 3600) / 60)
		const seconds = Math.floor(totalSeconds % 60)

		timeCode += days > 0 ? `${days}:` : ''
		timeCode += hours > 0 ? `${hours}:` : ''
		timeCode += minutes < 10 && days !== 0 && hours !== 0 ? `0${minutes}:` : `${minutes}:`
		timeCode += seconds < 10 ? `0${seconds}` : seconds

		return timeCode
	}

	/** Runs on first load of the Hook to load all YouTube data */
	useEffect(() => {
		const isOutdated = () => {
			const today = new Date()
			const dataDate = new Date(youtubeStats.dateModified._seconds * 1000)

			return !(
				today.getFullYear() === dataDate.getFullYear() &&
				today.getMonth() === dataDate.getMonth() &&
				today.getDate() === dataDate.getDate()
			)
		}

		if (Object.keys(youtubeStats).length === 0 || isOutdated()) {
			fetchStats()
		}

		// eslint-disable-next-line
	}, [])

	/** Runs when the YouTube data has been loaded or youtubeControlPanel changes data being viewed  */
	useEffect(() => {
		if (Object.keys(youtubeStats).length !== 0) {
			setBubbleData(getBubbleData())
		}

		// eslint-disable-next-line
	}, [youtubeStats, youtubeControlPanel])

	return { youtubeStats, bubbleData, formatSeconds }
}

export default useYouTube
