import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles'
import './index.scss'
import useCustomTheme from './theme'
import Routes from './Routes'
import { GlobalContextProvider } from './helpers'

const App = () => {
	const [theme, toggleDarkMode] = useCustomTheme()
	const themeConfig = createTheme(theme)

	return (
		<GlobalContextProvider>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={themeConfig}>
					<Router>
						<Routes theme={theme} tDM={toggleDarkMode} />
					</Router>
				</ThemeProvider>
			</StyledEngineProvider>
		</GlobalContextProvider>
	)
}

export default App
