import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Card, Grid, Hidden } from '@mui/material'
import { BubbleChart, InfoBoard } from './'
import { useYouTube } from '../../../../hooks'
import { TabRow } from '@mikeint0sh/the-keeton-cloud-components/components'
import { GlobalContext } from '../../../../helpers/GlobalContext'

const Root = styled(Grid)(() => ({
	//Content: Place in all minimal layouts
	height: '100%',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'space-between',
	flex: 1 //Pushes Footer to bottom
}))

const GridStyled = styled(Grid)(() => ({ display: 'flex' }))

const YouTube = () => {
	const { youtubeControlPanel } = useContext(GlobalContext)
	const { youtubeStats, bubbleData, formatSeconds } = useYouTube()

	return (
		<Root item container direction='column' spacing={4}>
			<Grid item>
				{/* Watch Later Summary */}
				<TabRow
					data={[
						{
							title: 'Videos',
							content: youtubeStats.playlistStats ? youtubeStats.playlistStats.total_videos : undefined
						},
						{
							title: 'Time',
							content: youtubeStats.playlistStats ? youtubeStats.playlistStats.total_time : undefined
						},
						{
							title: 'Days to Watch',
							content: youtubeStats.playlistStats ? youtubeStats.playlistStats.days_to_watch : undefined
						},
						{
							title: 'Average Video Length',
							content: youtubeStats.playlistStats ? youtubeStats.playlistStats.average_video_length : undefined
						}
					]}
				/>
			</Grid>

			<Grid item container spacing={4} justifyContent='flex-start'>
				<Hidden mdDown>
					<GridStyled item md={5}>
						<InfoBoard youtubeStats={youtubeStats} formatter={formatSeconds} />
					</GridStyled>
				</Hidden>

				<GridStyled item xx={12} md={7} container direction='row' sx={{ margin: 0, width: '100%' }}>
					<Card sx={{ width: '100%', pb: 1, px: { xs: 0, lg: 2.5 } }}>
						{bubbleData.length > 0 && (
							<BubbleChart
								data={bubbleData}
								formatter={
									youtubeControlPanel.view === 'channels' && youtubeControlPanel.comparison === 'seconds'
										? formatSeconds
										: undefined
								}
							/>
						)}
					</Card>
				</GridStyled>

				<Hidden mdUp>
					<GridStyled item xs={12}>
						<InfoBoard youtubeStats={youtubeStats} />
					</GridStyled>
				</Hidden>
			</Grid>
		</Root>
	)
}

export default YouTube
