import React from 'react'
import { Typography } from '@mui/material'

const Code = () => {
   return (
      <Typography variant='h1' gutterBottom>
         Code Portal
      </Typography>
   )
}

export default Code
