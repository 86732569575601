import React from 'react'
import { Typography } from '@mui/material'

const Settings = () => {
	return (
		<Typography variant='h1' gutterBottom>
			Settings
		</Typography>
	)
}

export default Settings
