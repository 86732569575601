import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { AppBar, Toolbar, Grid, Button, Typography, FormControl, Select, MenuItem } from '@mui/material'
import { MusicContext } from '../MusicContext'

const PREFIX = 'MenuBar'
const classes = {
	appBar: `${PREFIX}-appBar`,
	toolbar: `${PREFIX}-toolbar`,
	menuBtn: `${PREFIX}-menuBtn`,
	menuText: `${PREFIX}-menuText`,
	dropDown: `${PREFIX}-dropDown`
}

const StyledAppBar = styled(AppBar)(({ theme: { palette, breakpoints } }) => ({
	[`&.${classes.appBar}`]: {
		boxShadow: 'none',
		zIndex: 1000, //Above the Site Alert but it will move down for it
		backgroundColor: palette.menu,
		flexGrow: 1,
		display: 'flex'
	},
	[`& .${classes.toolbar}`]: {
		color: palette.secondary,
		backgroundColor: palette.menu //Fixes root background color being the wrong color
	},
	[`& .${classes.menuBtn}`]: {
		border: 0,
		margin: 0,
		paddingLeft: 10,
		paddingRight: 10,
		boxShadow: 'none',
		'&:hover': {
			boxShadow: 'none',
			backgroundColor: 'transparent'
		},
		[breakpoints.down('sm')]: {
			paddingLeft: 7,
			paddingRight: 7
		}
	},
	[`& .${classes.menuText}`]: {
		fontWeight: 300,
		paddingTop: 2,
		fontSize: 19,
		'&:hover': {
			borderBottom: `1px solid`,
			color: palette.secondary.main,
			borderBottomColor: palette.secondary.main,
			marginBottom: -1
		}
	},
	[`& .${classes.dropDown}`]: {
		fontWeight: 300,
		paddingTop: 5,
		paddingLeft: 10,
		fontSize: 19,
		width: '152px',
		'&:hover': {
			color: palette.secondary.main
		}
	}
}))

const MenuBar = (props) => {
	const { musicState, setMusicState } = useContext(MusicContext)
	/** Updates the state when the music type (tab) changes */
	const handleMenuChoice = (event) => {
		window.scrollTo(0, 0) //Ensures view loads at top
		const name = event.currentTarget.name
		setMusicState({
			...musicState,
			tab: name //Set the new value
		})
	}

	/** Updates the state when the music time period (drop down) changes */
	const handleTimePeriodChange = (event) => {
		const value = event.target.value
		console.log(value)
		setMusicState({
			...musicState,
			timePeriod: value //Set the new time period
		})
	}

	return (
		<StyledAppBar
			component='menu'
			position='static'
			className={classes.appBar}
			sx={{ height: { xs: props.height - 10, sm: props.height } }}
		>
			<Toolbar
				component='div'
				variant='dense'
				disableGutters={true}
				className={classes.toolbar}
				sx={{ height: { xs: props.height - 10, sm: props.height } }}
			>
				<Grid container direction='row' justifyContent='center' alignItems='center'>
					<Button color='secondary' onClick={handleMenuChoice} className={classes.menuBtn} name='artists'>
						<Typography
							color={musicState.tab === 'artists' ? 'secondary' : 'textPrimary'}
							className={classes.menuText}
							variant='h4'
							sx={{ paddingLeft: { xs: '4px', sm: 0 } }}
						>
							Artists
						</Typography>
					</Button>

					<Button color='secondary' onClick={handleMenuChoice} className={classes.menuBtn} name='tracks'>
						<Typography
							color={musicState.tab === 'tracks' ? 'secondary' : 'textPrimary'}
							className={classes.menuText}
							variant='h4'
						>
							Songs
						</Typography>
					</Button>

					<Button color='secondary' onClick={handleMenuChoice} className={classes.menuBtn} name='albums'>
						<Typography
							color={musicState.tab === 'albums' ? 'secondary' : 'textPrimary'}
							className={classes.menuText}
							variant='h4'
						>
							Albums
						</Typography>
					</Button>

					{/* sx={{ minWidth: 200 }}  */}
					<FormControl size='small' variant='standard'>
						{/* Last.FM time period options */}

						<Select
							MenuProps={{
								PaperProps: {
									sx: {
										backgroundColor: 'menu',
										backgroundImage: 'none', //Removes weird automatically added gradient hue
										'& .MuiMenuItem-root': { padding: 1 }
									}
								}
							}}
							value={musicState.timePeriod}
							onChange={handleTimePeriodChange}
							disableUnderline
							className={classes.dropDown}
						>
							<MenuItem value={'7day'}>Seven Days</MenuItem>
							<MenuItem value={'1month'}>One Month</MenuItem>
							<MenuItem value={'3month'}>Three Months</MenuItem>
							<MenuItem value={'6month'}>Six Months</MenuItem>
							<MenuItem value={'12month'}>One Year</MenuItem>
							<MenuItem value={'overall'}>All Time</MenuItem>
						</Select>
					</FormControl>
				</Grid>
			</Toolbar>
		</StyledAppBar>
	)
}

export default MenuBar
