import { useContext, useEffect } from 'react'
import { GlobalContext } from '../helpers/GlobalContext'
import { getMusicCharts as apiGetMusicCharts, getMusicScrobbleCount as apiGetMusicScrobbleCount } from '../helpers/api'
import { MusicContext } from '../views/Music/MusicContext'

const useMusic = (type) => {
	//Setup timePeriod to days key
	const diffInMs = new Date() - new Date('12-17-2014')
	const daysSinceStart = parseInt(diffInMs / (1000 * 60 * 60 * 24))
	const keys = { today: 1, '7day': 7, '1month': 30, '3month': 90, '6month': 180, '12month': 365, overall: daysSinceStart }

	const { topMusicCharts, setTopMusicCharts, scrobblesToday, setScrobblesToday, setLoading, setSiteAlert } =
		useContext(GlobalContext)
	//Hacky way put all of the music logic in one place; Only load musicState from context if it is actually accessible
	const { musicState } = useContext(MusicContext) ?? { musicState: {} }

	const handleError = (error) => {
		setTimeout(() => {
			setSiteAlert({
				//Shows a message at the top of the site
				title: 'Error Loading Music Data',
				description: `${error}`,
				variant: 'standard',
				severity: 'error',
				actionBtn: '',
				handleActionBtn: () => {},
				showMessage: true
			})
		}, 500)
	}

	/** Fetch all data for the Music Page defaulting to the past 7 days */
	const fetchAll = async (timePeriod = '7day') => {
		setLoading(3)
		const now = new Date()
		let promiseResult = await Promise.allSettled([
			apiGetMusicCharts('tracks', timePeriod),
			apiGetMusicCharts('albums', timePeriod),
			apiGetMusicCharts('artists', timePeriod),
			apiGetMusicScrobbleCount(keys[timePeriod]),
			apiGetMusicScrobbleCount(1)
		])

		const resultKeys = ['tracks', 'albums', 'artists', 'scrobbles', 'today']
		let topChartsData = {}
		let scrobblesTodayData = -1
		promiseResult.forEach((pResult, index) => {
			if (pResult.value.errors !== undefined) {
				console.error('Error hooks/Music fetchAll():', pResult.value.errors)
				handleError(pResult.value.errors) //TODO: Format error
			} else if (index < 4) {
				topChartsData[resultKeys[index]] = pResult.value.data
			} else {
				scrobblesTodayData = pResult.value.data
			}
		})

		setScrobblesToday(scrobblesTodayData)
		setTopMusicCharts({ ...topMusicCharts, ...topChartsData, timePeriod: timePeriod })
		setLoading(-3)
		const end = new Date()
		console.log('fetchAll took:', (end.getTime() - now.getTime()) / 1000)
	}

	/** Fetch Today's Scrobble count */
	const fetchScrobblesToday = async (showLoading) => {
		//Don't want to show loading when fetching every 2 minutes in the background (if showLoading is false)
		const scrobblesTodayNotLoaded = scrobblesToday === -1
		if (showLoading && scrobblesTodayNotLoaded) {
			setLoading(1)
		}
		// possibly pass a showLoading prop to only show loading when fetching on first load (from dashboard)
		const result = await apiGetMusicScrobbleCount(1) //(keys[period])
		if (result.errors) {
			console.error('Error hooks/Music fetchScrobblesToday():', result.errors)
			handleError(result.errors) //TODO: Format error
		} else {
			// setScrobbleCounts({ ...scrobbleCounts, [period]: result.data })
			setScrobblesToday(result.data)
		}
		if (showLoading && scrobblesTodayNotLoaded) {
			setLoading(-1)
		}
	}

	/** Runs on first load of the Hook to load all music data and setup Today's scrobbles refresh */
	useEffect(() => {
		if (type === 'all') {
			fetchAll()
		} else if (type === 'scrobbles') {
			fetchScrobblesToday(true) //Show loading indicator on initial load
		} else {
			console.warn('Warning hooks/music useEffect(): called with invalid type:', type)
			return
		}

		const interval = setInterval(() => {
			fetchScrobblesToday() //Don't show loading indicator on refresh
		}, 120000) // 2 * 60 * 1000 = Every 2 minutes refresh

		return () => clearInterval(interval) //Cleanup function stops the interval when the component is unmounted
		// eslint-disable-next-line
	}, [])

	//Hacky way put all of the music logic in one place; this should be inside MusicContext or MusicTilesView (if it breaks later)
	/** Updates the chart and scrobble data for the TimePeriod when the timePeriod changes */
	useEffect(() => {
		// console.log('attempting musicState hook', musicState)
		//Prevents change on first load (handled above), tab change, and if used from outside the Music context
		if (musicState.timePeriod && musicState.timePeriod !== topMusicCharts.timePeriod) {
			// console.log('musicState changed: timePeriod mismatch - fetching data', musicState.timePeriod, topMusicCharts.timePeriod)
			fetchAll(musicState.timePeriod)
		}
		// eslint-disable-next-line
	}, [musicState])

	return { topMusicCharts, scrobblesToday }
}

export default useMusic
