import React, { createContext, useState } from 'react'
export const MusicContext = createContext()

const MusicContextProvider = (props) => {
	const [musicState, setMusicState] = useState({
		tab: 'artists', //Currently selected tab (artists, albums, or tracks)
		timePeriod: '7day' //Currently selected time period (7day, 1month, 3month, 6month, 12month or overall)
	})

	return (
		<MusicContext.Provider value={{ musicState, setMusicState }}>
			{/* props.children - represents the components that this (ContextProvider) is going to wrap */}
			{props.children}
		</MusicContext.Provider>
	)
}

export default MusicContextProvider
