import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { styled, useTheme } from '@mui/material/styles'
import { Box, useMediaQuery } from '@mui/material'
import { TopBar } from './components'
import { SiteAlert } from '@mikeint0sh/the-keeton-cloud-components/components'
import { GlobalContext } from '../../helpers/GlobalContext'

const PREFIX = 'Minimal'
const classes = {
	root: `${PREFIX}-root`,
	content: `${PREFIX}-content`,
	siteAlert: `${PREFIX}-siteAlert`
}

const Root = styled('div')({
	[`&.${classes.root}`]: {
		paddingTop: 64,
		height: '100%'
		// marginBottom: -18
	},
	[`& .${classes.content}`]: {
		height: '100%'
	},
	[`& .${classes.siteAlert}`]: {
		//Site message fills the width of the content (excluding the sidebar: open or closed)
		width: `100%`,
		display: 'flex',
		position: 'fixed',
		top: 0,
		paddingLeft: 10,
		paddingRight: 10,
		flexGrow: 1,
		zIndex: 999
	}
})

const Minimal = ({ children }) => {
	const isDesktop = useMediaQuery(({ breakpoints }) => breakpoints.up('md'), { defaultMatches: true })
	const { transitions, palette } = useTheme()
	const { siteAlert } = useContext(GlobalContext)

	return (
		<Root className={classes.root}>
			<Box
				className={classes.siteAlert}
				sx={{ backgroundColor: siteAlert.showMessage ? palette.menu : 'transparent', paddingTop: isDesktop ? '64px' : '95px' }}
			>
				<SiteAlert GlobalContext={GlobalContext} />
			</Box>

			<TopBar />

			<main
				className={classes.content}
				style={{
					//Adds padding if there is a Site Message shown, extra padding if the message has a description too
					paddingTop: siteAlert.showMessage ? (siteAlert.description !== '' ? 73 : 52.5) : 0,
					transition: transitions.create('all', {
						easing: transitions.easing.sharp,
						duration: transitions.duration.enteringScreen
					})
				}}
			>
				{children}
			</main>
		</Root>
	)
}

Minimal.propTypes = {
	children: PropTypes.node,
	className: PropTypes.string
}

export default Minimal
